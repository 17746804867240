import React, { useEffect, useState } from 'react'
import { HiBars3CenterLeft } from 'react-icons/hi2'
import { FaArrowLeft } from 'react-icons/fa6';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from "../../../layout/Layout"
import { IoWallet } from 'react-icons/io5';
import { FaCheck } from "react-icons/fa6";
import axios from 'axios';
import { useAuthToken } from '../../../context/authToken';
const ContractMoney = [
    {
        name: 100
    },
    {
        name: 2000
    },
    {
        name: 500
    },
    {
        name: 1000
    },
    {
        name: 3000
    },
    {
        name: 5000
    },
    {
        name: 10000
    },
    {
        name: 20000
    },
]
const PaymentType = [
    {
        name: "ppp"
    },
    {
        name: 'agents'
    },
    {
        name: "mings"
    },
    {
        name: "super"
    }
]
const Recharge = () => {
    const navigate = useNavigate()

    const [amount, setAmount] = useState()
    const [balance, setBalance] = useState('')
    const [authToken, setAuthToken] = useAuthToken()
    const [select, setSelect] = useState()
    const location = useLocation()
    const handleSelect = (i) => {
        if (select === i) {
            return setSelect(null)
        }
        setSelect(i)

    }
    const authHandle = async () => {
        try {
            const data = await axios.post("https://hopeforcurrency.com/api/getUserData", authToken)
            setBalance(data?.data?.user?.balance)
        } catch (error) {
        }
    }
    useEffect(() => {
        authHandle()
    }, [authToken])
    return (
        <Layout>
            <header className='login-header wingo-top-header py-0  flex justify-between'>
                <div className="flex items-center py-1">
                    <span className='icons icons-hover' onClick={() => navigate(`/dashboard`, {
                        state: location.pathname,
                    })}><FaArrowLeft className='white-color' /></span>
                    <h2 className="heading-h2 text-white text-xl font-bold ms-5">Recharge</h2>
                </div>
                <div className="dashboard-bell-section">

                    <span className='icons icons-hover' onClick={() => navigate(`/recharge-record`)}>
                        <HiBars3CenterLeft />
                    </span>
                </div>
            </header>
            <div className="yello-color ">
                <div className="w-[80%] m-auto py-2 text-white text-justify">Note: If the recharge amount has been deducted but the amount has not arrived, please send the payment details and game ID screenshots to the online customer service of the game interface for processing.</div>
            </div>
            <div className="container-section">
                <div className="wingo-promotion-section">
                    <div className='pt-10'>
                        <h3 className="heading-h3 color-red text-center text-xl font-bold">Any problem? Contact customer service</h3>
                        <h2 className="heading-h2 text-center text-2xl font-medium mt-3">Balance: ₹ {Number(balance).toFixed(2)}</h2>
                    </div>
                    <div className='form-input-section m-6'>
                        <div className="input-box">
                            <span className="icons mr-2"><IoWallet /></span>
                            <input
                                type="number"
                                name="amount"
                                placeholder='Add Money'
                                autoComplete={false}
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                        <div className="recharge-amount-section mt-5 mx-1">
                            {ContractMoney.map((item, i) => (
                                <span key={i} className={" mx-2"} onClick={(i) => setAmount(item?.name)}>₹ {Number(item?.name)}

                                </span>
                            ))}

                        </div>
                        <div className="recharge-type-section mt-4 mx-1">
                            <hr />
                            <h3 className="heading-h3 text-gray-500 mt-1 mb-3">Payment</h3>
                            <ul>
                                {PaymentType.map((item, i) => (
                                    <li key={i} onClick={() => handleSelect(i)}>
                                        {select === i ? <p className='pr-4'><FaCheck /></p> : <p className='mr-8'></p>}
                                        {item.name}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className=" text-center pb-14">
                            <button type="submit" className='blue-btn mt-5' >Recharge</button>
                        </div>
                    </div>
                </div>
            </div>


        </Layout >
    )
}

export default Recharge
