import React, { useEffect, useState } from 'react'

import { FaArrowLeft } from "react-icons/fa6";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { IoMdKey } from "react-icons/io";
import { BiSolidCommentDots } from "react-icons/bi";
import { FaGift } from "react-icons/fa";
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../../layout/Layout';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
const ResetPassword = () => {
    const [formData, setFormData] = useState({ phone: '', password: '', otp: '' });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate()
    const location = useLocation()
    // time
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState("OTP")
    const [alertMessage, setAlertMessage] = useState('');
    const handleInputChange = async (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Form validation logic
        const phoneRegex = /^\d{10}$/; // Matches a 10-digit number
        const newErrors = {};
        if (formData.phone.trim() === '') {
            newErrors.phone = 'phone is required';
        }
        if (formData.password.trim() === '') {
            newErrors.password = 'Password is required';
        }
        if (formData.otp.trim() === '') {
            newErrors.otp = 'Verification Code is required';
        }


        else if (!phoneRegex.test(formData.phone)) {
            newErrors.phone = 'Please enter a valid 10-digit phone number'
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        // Submit the form if validation passes
        // For demonstration, let's just log the form data
        try {
            const data = await axios.post("https://hopeforcurrency.com/api/changepassword", formData)
            // console.log('Form submitted chanf:', data);

            if (data.data) {
                navigate('/login')
            }
        } catch (error) {
            setAlertMessage('Mobile number and password wrong');
            setTimeout(() => {
                setAlertMessage(''); // Clear the alert message after 5 seconds
            }, 3000);
        }
    };
    const HandleClick = async () => {
        const phoneRegex = /^\d{10}$/; // Matches a 10-digit number
        const newErrors = {};
        if (formData.phone.trim() === '') {
            newErrors.phone = 'phone is required';
        }
        else if (!phoneRegex.test(formData.phone)) {
            newErrors.phone = 'Please enter a valid 10-digit phone number'
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        try {
            const otp = await axios.post("https://hopeforcurrency.com/api/sendotp", { phone: formData.phone })
            console.log("otp", otp)
            if (otp?.data) {
                setSeconds(59)
            }
        } catch (error) {
            setAlertMessage('something went wrong');
            setTimeout(() => {
                setAlertMessage(''); // Clear the alert message after 5 seconds
            }, 3000);
        }


    }
    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1)
            }
            // whien seond reach 0 descreese minnuts
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval)
                } else {
                    setSeconds(59)
                    setMinutes(minutes - 1)
                }
            }
        }, 1000)
        return () => {

            clearInterval(interval)
        }

    }, [seconds]);



    return (
        <Layout>
            {/* top bar */}
            <div className="">
                <header className='login-header'>
                    <div className="flex items-center">
                        <span className='icons icons-hover' onClick={() => navigate("/dashboard", {
                            state: location.pathname,
                        })}><FaArrowLeft className='white-color' /></span>
                        <h2 className="heading-h2 text-white text-xl font-medium ms-5">Reset Password</h2>
                    </div>
                </header>
                {alertMessage &&
                    <Stack sx={{ width: '100%' }} spacing={2} className='mt-1'>
                        <Alert variant="filled" severity="error">
                            {alertMessage}
                        </Alert>
                    </Stack>
                }
                <div className="container-section">
                    <form onSubmit={handleSubmit}>
                        <div className='form-input-section'>
                            <div className="input-box">
                                <span className="icons mr-2"><MdOutlinePhoneAndroid /></span>
                                <input
                                    type="number"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    placeholder='Mobile Number'
                                    autoComplete="off"
                                />
                            </div>
                            {errors.phone && <span className='error' >{errors.phone}</span>}
                        </div>

                        <div className='form-input-section'>
                            <div className="otp-verification-section">
                                <div className="input-box">
                                    <span className="icons mr-2"><BiSolidCommentDots /></span>
                                    <input
                                        type="number"
                                        name="otp"
                                        value={formData.otp}
                                        onChange={handleInputChange}
                                        placeholder='Verification Code'
                                        autoComplete="off"
                                    />
                                </div>
                                <button className={seconds > 0 ? "otp-btn disablebtn" : 'otp-btn'} onClick={() => HandleClick()}
                                    disabled={seconds > 0}
                                >
                                    {seconds > 0 ? `WAIT ${seconds}s` : "OTP"}</button>

                            </div>
                            {errors.otp && <span className='error' >{errors.otp}</span>}
                        </div>
                        <div className='form-input-section'>
                            <div className="input-box">
                                <span className="icons mr-2"><IoMdKey /></span>
                                <input
                                    type="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    placeholder='New Password'
                                    autoComplete="off"
                                />
                            </div>
                            {errors.password && <span className='error' >{errors.password}</span>}
                        </div>

                        <div className="login-button text-center">
                            <button type="submit" className='login-btn'>Continue</button>
                        </div>

                    </form>
                </div>
            </div>

        </Layout>
    )
}

export default ResetPassword
