import React, { useEffect, useState } from 'react'

import { HiBars3CenterLeft } from "react-icons/hi2";

import Box from '@mui/material/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import Layout from '../../../layout/Layout';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import "./promotion.css"
import { Link, useNavigate } from 'react-router-dom';
import ApplyBalanceModal from './ApplyBalanceModal';
import axios from 'axios';
import { FaArrowLeft } from 'react-icons/fa';
import { useAuthToken } from '../../../context/authToken';

const Promotion = () => {
    const navigate = useNavigate()
    const [value, setValue] = React.useState('1');
    const [bonus, setBonus] = useState()
    const [authToken, setAuthToken] = useAuthToken()
    const [inviteCode, setInviteCode] = useState('')
    const [level1C, setLevel1C] = useState("")
    const [level1P, setLevel1P] = useState("")
    const [level2C, setLevel2C] = useState("")
    const [level2P, setLevel2P] = useState("")

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const handleUpdate = async (e) => {
        try {
            const data = await axios.post("https://hopeforcurrency.com/api/downline", { auth: authToken.auth })
            console.log("ddd", data?.data?.totalCounts.level1)
            setInviteCode(data?.data?.invite_code)
            setLevel1C(data?.data?.totalBalances[1])
            setLevel1P(data?.data?.totalCounts.level1)
            setBonus(data?.data?.bonus)
            setLevel2P(data?.data?.totalCounts.level2)
            setLevel2C(data?.data?.totalBalances[2])
        } catch (error) {

        }
    };
    useEffect(() => {
        handleUpdate()
    }, [])
    return (
        <Layout>
            {/* header */}
            <header className='login-header wingo-top-header  flex justify-between'>
                <div className="flex items-center">

                    <span className='icons icons-hover' onClick={() => navigate("/")}><FaArrowLeft className='white-color' /></span>
                    <h2 className="heading-h2 text-white text-xl font-bold ms-5 py-3">Promotion</h2>
                </div>
                <div className="dashboard-bell-section">

                    <span className='icons icons-hover'>
                        <Button onClick={handleClick}>
                            <HiBars3CenterLeft />
                        </Button>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <Typography sx={{ px: 3, py: 1 }}><Link>Promotion Record</Link></Typography>
                            <Typography sx={{ px: 3, py: 1 }}><Link>Bonus Record</Link></Typography>
                            <Typography sx={{ px: 3, py: 1 }}><Link>Apply Record</Link></Typography>
                        </Popover>
                    </span>
                </div>
            </header>
            <div className="container-section">
                <div className="wingo-promotion-section">
                    <div className="bonus pt-8">
                        <h2 className="heading-h2 text-center text-2xl font-bold">Bonus: ₹ {(Number(bonus))?.toFixed(2)}</h2>
                        <div className=" text-center">
                            <button type="submit" className='apply-btn mt-4'><ApplyBalanceModal selectName={"Apply to Balance"} /></button>
                            {/* <button type="submit" className='apply-btn mt-4'><ApplyBalanceModal selectName={"Apply to Balance"} value={bonus} setValue={setBonus} handleUpdate={handleUpdate} /></button> */}
                        </div>
                    </div>


                    <div className="wingo-promotion-section-link pb-10 mt-4">
                        <hr />
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab label="Level 1" value="1" className='w-[50%]' />
                                        <Tab label="Level 2" value="2" className='w-[50%]' />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <div className='flex justify-between text-center w-[70%] mt-3 m-auto'>
                                        <div>
                                            <h3 className="heading-h3 text-xl font-medium">Total People</h3>
                                            <h3 className="heading-h3 text-xl font-medium">{level1P}</h3>
                                        </div>
                                        <div>
                                            <h3 className="heading-h3 text-xl font-medium">Contribution</h3>
                                            <h3 className="heading-h3 text-xl font-medium">₹ {Number(level1C).toFixed(2)}</h3>
                                        </div>
                                    </div>

                                    <div className='mx-3 mt-3 pt-4 pb-1 border-b-2 border-slate-500'>
                                        <button disabled className='' style={{ fontSize: "12px" }}>
                                            My Promotion Code
                                        </button>
                                        <p> {inviteCode}</p>
                                    </div>
                                    <div className='mx-3 mt-3 p-4 pb-10  border-b-2 border-slate-500'>
                                        <button disabled className='' style={{ fontSize: "12px" }}>
                                            My Promotion Link
                                        </button>
                                        <p className=''> {`https://game.hopeforcurrency.com/register?r_code=${inviteCode}`} </p>
                                    </div>
                                    <div className=" text-center">
                                        <button type="submit" className='gray-btn mt-5' ><Link to={`https://game.hopeforcurrency.com/register?r_code=${inviteCode}`} target='_blank'>Open Link</Link></button>
                                    </div>
                                </TabPanel>
                                <TabPanel value="2">
                                    <div className='flex justify-between text-center w-[70%] mt-3 m-auto'>
                                        <div>
                                            <h3 className="heading-h3 text-xl font-medium">Total People</h3>
                                            <h3 className="heading-h3 text-xl font-medium">{level2P}</h3>
                                        </div>
                                        <div>
                                            <h3 className="heading-h3 text-xl font-medium">Contribution</h3>
                                            <h3 className="heading-h3 text-xl font-medium">₹ {Number(level2C).toFixed(2)}</h3>
                                        </div>
                                    </div>

                                    <div className='mx-3 mt-3 pt-4 pb-1 border-b-2 border-slate-500'>
                                        <button disabled className='' style={{ fontSize: "12px" }}>
                                            My Promotion Code
                                        </button>
                                        <p> {inviteCode}</p>
                                    </div>
                                    <div className='mx-3 mt-3 p-4 pb-10  border-b-2 border-slate-500'>
                                        <button disabled className='' style={{ fontSize: "12px" }}>
                                            My Promotion Link
                                        </button>
                                        <p className=''> {`https://game.hopeforcurrency.com/register?r_code=${inviteCode}`} </p>
                                    </div>
                                    <div className=" text-center">
                                        <button type="submit" className='gray-btn mt-5' ><Link to={`https://game.hopeforcurrency.com/register?r_code=${inviteCode}`} target='_blank'>Open Link</Link></button>
                                    </div>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Promotion
// Given data



