import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthToken } from '../../../context/authToken';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 310,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,

};


export default function ApplyBalanceModal({ selectName, handleUpdate, value, setValue }) {
    const [open, setOpen] = React.useState(false);
    const [level1C, setLevel1C] = useState("")
    const [level2C, setLevel2C] = useState("")

    const navigate = useNavigate()
    const [authToken, setAuthToken] = useAuthToken()
    const [bonuss, setBonus] = useState('')
    const [input, setInput] = useState('')

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSubmit = async (e) => {
        try {
            const data = await axios.post("https://hopeforcurrency.com/api/downline", { auth: authToken.auth })
            setBonus(data?.data?.bonus)
        } catch (error) {

        }
    };
    const handleBonus = async (e) => {
        try {
            const data = await axios.post("https://hopeforcurrency.com/api/bonus", { auth: authToken.auth, amount: input })

        } catch (error) {

        }
    };
    useEffect(() => {
        handleSubmit()
    }, [])



    return (
        <>
            <div>
                {/* <Button onClick={handleOpen}>{Icons}</Button> */}
                <h3 onClick={handleOpen}>  {selectName}</h3>

            </div>

            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro, quibusdam.</p> */}
                    <div className="wingo-modal-box">
                        <div className="name-section gray" >
                            <h3 className="heading-h3" >
                                Apply to Balance
                            </h3>
                        </div>
                        <div className="modal-content">
                            <p>Bonus</p>
                            <input type="number" name="" id="bonusinput" value={input} onChange={(e) => setInput(e.target.value)} className='w-[100%] border-b-2 border-slate-500' />
                            <div className=" text-center mt-5" value={value} onChange={(e) => setValue(e.target.value)}>
                                <button type="submit" className='gray-btn mt-5' onClick={() => setInput(Number(bonuss))} ><Link >Apply All</Link></button>
                            </div>
                            <div className="cancel-confirm mt-5 pt-10">
                                <button className="close-modal" onClick={handleClose}>CANCEL</button>
                                {/* <button className='confirm' onClick={() => submitBet()} >CONFIRM</button> */}
                                <button className='confirm' onClick={() => { handleClose(); handleOpen(); handleBonus() }} >CONFIRM</button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}