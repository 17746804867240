import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { BsPlusLg } from 'react-icons/bs';
import { IoMdClose } from "react-icons/io";
import { Link } from 'react-router-dom';
import { FiMinus } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import axios from 'axios';
import { useAuthToken } from '../../context/authToken';
import { useEffect } from 'react';
import asyncHandler from 'async-handler';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 375,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,

};
const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 170,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,

};
// const ContractMoney = [10, 100, 1000, 10000]
const ContractMoney = [
    {
        name: 10
    },
    {
        name: 100
    },
    {
        name: 1000
    },
    {
        name: 10000
    },
]
export default function GreenModal({ selectName, GVRClassName, period_id }) {
    const [open, setOpen] = React.useState(false);
    const [opennew, setOpenNew] = React.useState(false);
    const [contractM, setContantractM] = React.useState(0)
    const [amount, setAmount] = React.useState(10)
    const [count, setCount] = useState(1)
    const [colors, setColors] = useState("")
    const [game, setGame] = useState('')
    // const [period_id, setCurrentPeriodId] = useState("")
    const [authToken, setAuthToken] = useAuthToken()

    const increase = () => setCount(count + 1)
    const decrease = () => {
        if (count > 1) {
            setCount(count - 1)

        }
    }
    const handleContractM = (i) => {
        if (contractM === i) {
            return setContantractM(null)
        }
        setContantractM(i)
    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleOpenNew = () => setOpenNew(true);
    const handleCloseNew = () => setOpenNew(false);


    // local storage
    useEffect(() => {

        const interval = setInterval(() => {
            const savedData = localStorage.getItem('colors');
            if (savedData) {
                setColors(savedData);
            }
            const savedDatas = localStorage.getItem('game');
            if (savedDatas) {
                setGame(savedDatas);
            }
        }, 1000)
        return () => {

            clearInterval(interval)
        }
    }, [colors, game]);


    const submitBet = async () => {
        try {
            const data = await axios.post("https://hopeforcurrency.com/api/wingo_bet", {
                auth: authToken.auth,
                amount: amount,
                color: colors,
                number: 0,
                count: count,
                period_id: period_id,
                game: game,
            }
            )
        } catch (error) {
        }
    }
    return (
        <>
            <div>
                {/* <Button onClick={handleOpen}>{Icons}</Button> */}
                <h3 onClick={handleOpen}>  {selectName}</h3>
                <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={style}>
                        {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro, quibusdam.</p> */}

                        <div className="wingo-modal-box">
                            <div className={GVRClassName}>

                                <h3 className="heading-h3" >
                                    <div className={GVRClassName === "name-section blue" ? "show-select" : "hide-select"} >Select</div>  {selectName} </h3>
                            </div>
                            <div className="modal-content">
                                <h5 className="heading-h5">Contract Money</h5>
                                <div className="select-price-section">
                                    {ContractMoney.map((item, i) => (
                                        <span key={i} className={contractM === i ? "active" : ""} onClick={(i) => { handleContractM(i); setAmount(item?.name); }}>{Number(item?.name)}
                                            {/* {console.log("object", item.name)} */}
                                        </span>
                                    ))}
                                    {/* <span>100</span>
                                <span>1000</span>
                                <span>10000</span> */}
                                </div>
                                <div className="selection-increase-number">
                                    <h5 className="heading-h5">Number</h5>
                                    <div className='increase-decrease flex justify-between'>
                                        <button><span><FiMinus onClick={decrease} /></span></button>
                                        <span>{count}</span>
                                        <button><GoPlus onClick={increase} /></button>
                                    </div>
                                </div>
                                <h5 className="heading-h5 mt-8">Total contract money is {amount * count}</h5>
                                <div className="check-box-agree">
                                    <input type="checkbox" id='22' />
                                    <label htmlFor="22">I Agree</label>
                                    <Link>PRESALE RULE</Link>
                                </div>
                                <div className="cancel-confirm">
                                    <button className="close-modal" onClick={handleClose}>CANCEL</button>
                                    {/* <button className='confirm' onClick={() => submitBet()} >CONFIRM</button> */}
                                    <button className='confirm' onClick={() => { submitBet(); handleClose(); handleOpenNew(); }} >CONFIRM</button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>

            <Modal
                keepMounted
                open={opennew}
                onClose={handleCloseNew}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={styles}>
                    {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro, quibusdam.</p> */}
                    <div className="wingo-modal-box">
                        <div className="name-section gray">
                            <h3 className="heading-h3">

                                Completed
                            </h3>
                        </div>
                        <div className="modal-content">
                            <h5 className="heading-h5">Your contract is successful completed</h5>
                            <div className="cancel-confirm">
                                <button className="ok-btn" onClick={handleCloseNew}>OK</button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}