import React, { useState } from 'react'
import { HiBars3CenterLeft } from 'react-icons/hi2'
import { FaArrowLeft } from 'react-icons/fa6';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Layout from "../../layout/Layout"

import { FaCheck } from "react-icons/fa6";
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { FaRegCreditCard } from "react-icons/fa6";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useMoney } from '../../context/money';
const Windrawal = () => {
    const navigate = useNavigate()

    const [amount, setAmount] = useState()
    const location = useLocation()
    const [select, setSelect] = useState()
    const { money, updateMoneyData } = useMoney();
    const handleSelect = () => {
        setSelect(!select)
    }

    return (
        <Layout>
            <header className='login-header wingo-top-header py-0  flex justify-between'>
                <div className="flex items-center py-1">
                    <span className='icons icons-hover' onClick={() => navigate("/dashboard", {
                        state: location.pathname,
                    })}><FaArrowLeft className='white-color' /></span>
                    <h2 className="heading-h2 text-white text-xl font-bold ms-5">Windrawal</h2>
                </div>
                <div className="dashboard-bell-section">

                    <span className='icons icons-hover'>
                        <HiBars3CenterLeft />
                    </span>
                </div>
            </header>
            <div className="yello-color ">
                <div className="w-[80%] m-auto py-2 text-white text-justify">Note: If the Windrawal amount has been deducted but the amount has not arrived, please send the payment details and game ID screenshots to the online customer service of the game interface for processing.</div>
            </div>
            <div className="container-section">
                <div className="wingo-promotion-section">
                    <div className='pt-10'>
                        <h3 className="heading-h3 color-red text-center text-xl font-bold">Any problem? Contact customer service</h3>
                        <h2 className="heading-h2 text-center text-2xl font-medium mt-3">Balance: ₹ {Number(money[1]).toFixed(2)}</h2>
                    </div>
                    <div className='form-input-section m-6'>
                        <div className="input-box">
                            <span className="icons mr-2"><RiMoneyDollarCircleFill /></span>
                            <input
                                type="number"
                                name="amount"
                                placeholder='Enter Withdrawal Amount'
                                autoComplete={false}
                                value={amount}
                            />
                        </div>
                        <p className='text-gray-500 '>Fee:0, to account:0</p>
                        <div className="recharge-type-section mt-4 mx-1">
                            <hr />
                            <h3 className="heading-h3 text-gray-500  mt-1 mb-3">Payout</h3>
                            <ul>

                                <li className='text-base text-gray-900 font-bold'>
                                    <p className='pr-8 '><FaCheck /></p>
                                    Use Bank to withdrawal(ppp)
                                </li>
                            </ul>
                            <ul className={select ? 'border-b-2 border-t-2' : ""}>
                                <li className='text justify-between duration-[0.5s]' onClick={() => handleSelect()}>

                                    <Link className='flex items-center text-base text-gray-900 font-bold'>
                                        <p className='pr-8 '><FaRegCreditCard /></p>
                                        Select Bank Card
                                    </Link>
                                    <span className='icons'> <MdKeyboardArrowDown /></span>
                                </li>
                                <li className={select ? "d-block " : "d-none duration-[0.5s]"} onClick={() => navigate("/add-bank-card")}><Link className='text-base text-gray-900 font-bold ml-12'>Add Bank Card</Link> </li>
                            </ul>

                        </div>
                        <div className=" text-center pb-14">
                            <button type="submit" className='blue-btn mt-5' >Windrawal</button>
                        </div>
                    </div>
                </div>
            </div>


        </Layout >
    )
}

export default Windrawal
