import React, { useState } from 'react'
import "./home.css"
import Logo from "../../asstes/cooe_logo.png"
import { IoSettingsSharp } from "react-icons/io5";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';


import SliderImg1 from "../../asstes/slider1.jpg"
import SliderImg2 from "../../asstes/slider2.jpg"
import WingoImg from "../../asstes/wingo_enter.png"
import LuckyImg from "../../asstes/lucky_hit_enter.png"
import AviatorImg from "../../asstes/aviator_enter.png"
import MinesImg from "../../asstes/mines_enter.png"
// import required modules
import { Autoplay, Navigation } from 'swiper/modules';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../../layout/Layout';
import { useTheme } from '../../context/ThemeProvider';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
const HomePage = () => {
    const { theme, toggleTheme } = useTheme();

    const navigate = useNavigate()
    const [mode, setMode] = useState('light'); // Default mode is light

    const [value, setValue] = React.useState('1');
    const [bonus, setBonus] = useState()
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const toggleMode = () => {
        setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
    };
    return (
        <Layout>
            {/* top bar */}
            <div className="">
                <header className='home-top-header'>
                    <div className="top">
                        <div className="logo">
                            <img src={Logo} alt="" />
                        </div>
                        <div className="theme-section">
                            {/* <span><IoSettingsSharp /></span> */}

                            <span className='icons icons-hover'>
                                <Button onClick={handleClick}>
                                    <IoSettingsSharp className='' style={{ fontSize: "40px" }} />
                                </Button>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <Typography sx={{ px: 3, py: 1 }} onClick={toggleTheme}><Link> {theme === "dark" ? "Light Mode" : "Dark Mode"} </Link></Typography>
                                    <Typography sx={{ px: 3, py: 1 }}><Link>Notice</Link></Typography>
                                </Popover>
                            </span>
                        </div>


                    </div>
                    <div className="top-name">
                        <h3 className="heading-h3">CODE GAMES</h3>
                    </div>
                </header>
            </div>

            {/* slider section */}



            <div className="home-slider-section">
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}

                    navigation={true}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={SliderImg1} alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="slider-img">
                            <img src={SliderImg2} alt="" />
                        </div>
                    </SwiperSlide>

                </Swiper>
            </div>
            {/* game type */}
            <div className="container-section ">
                <div className="grid grid-cols-12 gap-2 mat-10 mb-10">
                    <div className="sm:col-span-6">
                        <div className="game-type-box" onClick={() => navigate("/wingo")}>
                            <img src={WingoImg} alt="" />
                            <p className="para text-center mt-2">Win Go</p>
                        </div>
                    </div>
                    <div className="sm:col-span-6" onClick={() => navigate("/luckyhit")}>
                        <div className="game-type-box">
                            <img src={LuckyImg} alt="" />
                            <p className="para text-center mt-2">Lucky Hit</p>
                        </div>
                    </div>
                    <div className="sm:col-span-6" onClick={() => navigate("/aviator")}>
                        <div className="game-type-box">
                            <img src={AviatorImg} alt="" />
                            <p className="para text-center mt-2">Aviator</p>
                        </div>
                    </div>
                    <div className="sm:col-span-6" onClick={() => navigate("/mines")}>
                        <div className="game-type-box">
                            <img src={MinesImg} alt="" />
                            <p className="para text-center mt-2">Mines</p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default HomePage
