import React, { useState } from 'react'
import Layout from '../../layout/Layout'
import { FaArrowLeft } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'

import { FaPlaneDeparture } from "react-icons/fa";
import TablePagination from '@mui/material/TablePagination';
const RechargeRecord = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [page, setPage] = useState(2);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Layout>
            <header className='login-header wingo-top-header py-0  flex justify-between'>
                <div className="flex items-center py-1">
                    <span className='icons icons-hover' onClick={() => navigate("/recharge", {
                        state: location.pathname,
                    })}><FaArrowLeft className='white-color' /></span>
                    <h2 className="heading-h2 text-white text-xl font-bold ms-5">Recharge</h2>
                </div>
            </header>

            <div className="container-section">
                <div className="shadow-section">
                    <div className="prediction-section-color">


                        <div className="transaction-section">
                            <ul>
                                <li>
                                    <div className='details'>
                                        <div className='icons bg-green'>< FaPlaneDeparture /></div>
                                        <div className="transaction-name">
                                            <h2 className="heading-h2">100.00 Success</h2>
                                            <p>agents</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="">024-03-16 20:18</p>
                                    </div>
                                </li>

                            </ul>
                            <div className="wingo-pagination">

                                <TablePagination
                                    component="div"
                                    count={100}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default RechargeRecord
