import { useState, useEffect } from "react";

import { Outlet } from "react-router-dom";
import axios from "axios";
import Spinner from "./Spinner";
import { useAuthToken } from "../../context/authToken";

export default function PrivateRoute() {
    const [ok, setOk] = useState(false);
    const [authToken, setAuthToken] = useAuthToken();

    useEffect(() => {


        const authCheck = async () => {
            try {
                const res = await axios.post("https://hopeforcurrency.com/api/getUserData", authToken);

                if (res.data.user) {
                    setOk(true);
                } else {
                    setOk(false);
                }

            } catch (error) {

            }
        }


        if (authToken) authCheck();


        window.onload = function () {
            authCheck();
        }
    }, [authToken]);

    return ok ? <Outlet /> : <Spinner />;
}







// window.onload = function () {

//     // Check if the document is fully loaded and there are no errors
//     if (document.readyState === 'complete') {
//         // If everything loaded properly, display the content
//         document.getElementById('main-loader-page').style.display = 'none';
//     } else {
//         console.log("object")
//         // If there was an error loading the page, display the error message
//         document.getElementById('main-loader-page').style.display = 'block';
//     }
// };