import React, { useState } from 'react'
import "./user.css"
import { FaArrowLeft } from "react-icons/fa6";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { IoMdKey } from "react-icons/io";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuthToken } from '../../context/authToken';
import Layout from '../../layout/Layout';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
const Login = () => {
    const [formData, setFormData] = useState({ phone: '', password: '' });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate()
    const [authToken, setAuthToken] = useAuthToken()
    // console.log("auth login", authToken)
    const [alertMessage, setAlertMessage] = useState('');
    const location = useLocation()
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Form validation logic
        const phoneRegex = /^\d{10}$/; // Matches a 10-digit number
        const newErrors = {};
        if (formData.phone.trim() === '') {
            newErrors.phone = 'phone is required';
        }
        else if (formData.password.trim() === '') {
            newErrors.password = 'Password is required';
        }

        else if (!phoneRegex.test(formData.phone)) {
            newErrors.phone = 'Please enter a valid 10-digit phone number'
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        // Submit the form if validation passes
        // For demonstration, let's just log the form data
        // console.log('Form submitted:', formData);
        else {
            try {
                const res = await axios.post("https://hopeforcurrency.com/api/login", formData)
                if (res.data) {
                    // console.log("login", res.data.authToken)

                    setAuthToken({
                        ...authToken,
                        // user: res.data.user,
                        auth: res.data.authToken
                    })
                    localStorage.setItem('authToken', JSON.stringify(res.data))
                    navigate(location.state || "/")

                } else {

                }
            } catch (error) {
                setAlertMessage('Mobile number and password wrong');
                setTimeout(() => {
                    setAlertMessage(''); // Clear the alert message after 5 seconds
                }, 3000);
            }
        }
    };
    return (
        <Layout>
            {/* top bar */}
            <div className="">
                <header className='login-header'>
                    <div className="flex items-center">
                        <span className='icons cursor-pointer icons-hover' onClick={() => navigate("/dashboard", {
                            state: location.pathname,
                        })}><FaArrowLeft className='white-color' /></span>
                        <h2 className="heading-h2 text-white text-2xl font-bold ms-5">Login</h2>
                    </div>

                </header>
                {alertMessage &&
                    <Stack sx={{ width: '100%' }} spacing={2} className='mt-1'>
                        <Alert variant="filled" severity="error">
                            {alertMessage}
                        </Alert>
                    </Stack>
                }

                <div className="container-section">

                    <form onSubmit={handleSubmit}>
                        <div className='form-input-section'>
                            <div className="input-box">
                                <span className="icons mr-2"><MdOutlinePhoneAndroid /></span>
                                <input
                                    type="number"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    placeholder='Mobile Number'
                                    autoComplete={false}
                                />
                            </div>
                            {errors.phone && <span className='error' >{errors.phone}</span>}
                        </div>
                        <div className='form-input-section'>
                            <div className="input-box">
                                <span className="icons mr-2"><IoMdKey /></span>
                                <input
                                    type="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    placeholder='Password'
                                    autoComplete='false'
                                />
                            </div>
                            {errors.password && <span className='error' >{errors.password}</span>}
                        </div>
                        <div className="login-button text-center">
                            <button type="submit" className='login-btn'>Login</button>
                        </div>
                        <div className='text-center mt-[30px]'>
                            <div className="register-forget">
                                <button onClick={() => navigate("/register")}>Register</button>
                                <button onClick={() => navigate('/reset-password')}>Forget Password?</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </Layout>
    )
}

export default Login
