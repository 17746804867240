import React, { useEffect, useState } from 'react'

import { IoWallet } from 'react-icons/io5'
import { FaQuestionCircle } from "react-icons/fa";
import { FaArrowLeft } from 'react-icons/fa6';
import TextField from '@mui/material/TextField';
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './mines.css'
import { MdOutlineQuestionMark } from "react-icons/md";
import MinesRecord from './MinesRecord';
import axios from 'axios';
import { useAuthToken } from '../../context/authToken';
import { FaRegListAlt } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { GoStarFill } from "react-icons/go";
import { PiCircleDashedFill } from "react-icons/pi";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useMoney } from '../../context/money';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 170,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,

};
const Mines = () => {
    const navigate = useNavigate()
    const [myPeriodRecord, setMyPeriodRecod] = useState([])
    const [totalPageRecord, setTotalPageRecord] = useState(Number)
    const [openHead, setOpenHead] = useState(false)
    const [amount, setAmount] = useState(50)
    const handleOpenHead = () => setOpenHead(!openHead)
    const [history, setHistory] = useState([])
    const [starting, setStarting] = useState(1)
    const [ending, setEnding] = useState(10)
    const [opennew, setOpenNew] = React.useState(false);
    const handleOpenNew = () => setOpenNew(true);
    const handleCloseNew = () => setOpenNew(false);

    const [authToken, setAuthToken] = useAuthToken()
    const [bet, setBet] = useState(false)
    const [loading, setLoading] = useState(false)
    const [cashout, setCashout] = useState(false)
    const [traps, setTraps] = useState(1);
    const [stop, setStop] = useState('')
    const [values, setValues] = useState(Array(25).fill(null));
    const [alertMessage, setAlertMessage] = useState('');
    const [balance, setBalance] = useState(Number);
    const [messages, setMessages] = useState("")
    //start record
    const [openAllRecord, setOpenAllRecord] = useState(null)
    const handleOpenAllRecord = (i) => {
        if (openAllRecord === i) {
            return setOpenAllRecord(null)
        }
        setOpenAllRecord(i)
    }

    const handlePredictionRecord = async () => {
        try {
            const data = await axios.post("https://hopeforcurrency.com/api/boom_history", { auth: authToken.auth, starting: starting, ending: ending })
            setMyPeriodRecod(data?.data?.results)

            setTotalPageRecord(data?.data?.total_rows)
        } catch (error) {

        }
    }
    const handleIncreasePagination = () => {
        setStarting(Number(starting) + 10)
        setEnding(Number(ending) + 10)

    }
    const handleDecreasePagination = () => {
        setStarting(Number(starting) - 10)
        setEnding(Number(ending) - 10)
    }

    //end record



    const maxwin = traps * 1198.54
    const handleChange = (event) => {
        setTraps(event.target.value);
    };

    const increease = () => {
        setAmount(Number(amount) + 10)
    }
    const decrease = () => {
        if (amount >= 20) {
            setAmount(Number(amount) - 10)
        }
    }


    const handleGetHistoryResult = async () => {
        try {
            const data = await axios.post("https://hopeforcurrency.com/api/aviator_getresult")
            setHistory(data?.data?.results)
            // console.log("object hihji", data?.data?.results            )
        } catch (error) {

        }
    }

    const authHandle = async () => {
        try {
            const data = await axios.post("https://hopeforcurrency.com/api/getUserData", authToken)
            setBalance(data?.data?.user?.balance)
        } catch (error) {
        }
    }
    const handleBet = async () => {
        try {
            if (loading) return; // Prevent multiple clicks
            setLoading(true);
            const data = await axios.post("https://hopeforcurrency.com/api/boom_bet", { auth: authToken.auth, amount: amount })
            setMessages(data?.data?.message)
            setBet(data?.data?.success)
            handleOpenNew()
            if (data?.data?.success) {
                authHandle()
            }
            setLoading(false)
        } catch (error) {

        }
    }
    const handleCashout = async () => {
        try {
            if (loading) return; // Prevent multiple clicks
            setLoading(true);
            const data = await axios.post("https://hopeforcurrency.com/api/cashout", { auth: authToken.auth })
            setCashout(data?.data?.result)
            setLoading(false)
        } catch (error) {

        }
    }
    const handleOpenBox = async () => {
        try {
            const response = await axios.post("https://hopeforcurrency.com/api/openbox", { auth: authToken.auth });
            const data = response.data.message;
            setStop(data);
            return data; // Return the data message
        } catch (error) {
            // Handle errors if needed
            console.error("Error:", error);
            throw error; // Re-throw the error to be caught by the caller if necessary
        }
    }
    const handleClick = async (index) => {
        if (stop === null) {
            return;
        }
        try {
            const newDataMessage = await handleOpenBox(); // Wait for handleOpenBox() to complete execution and get the data message
            const newValues = [...values];
            newValues[index] = newDataMessage === "dead" ? <PiCircleDashedFill /> : <GoStarFill />; // Store the retrieved message in newValues
            setValues(newValues);
        } catch (error) {
            // Handle errors if needed
            console.error("Error:", error);
        }
    }



    useEffect(() => {
        authHandle()
        if (stop === "dead") {
            setAlertMessage(`The game is over, you have lost ${amount}, wait few seconds and then start a new game `);
            setTimeout(() => {
                setAlertMessage(''); // Clear the alert message after 5 seconds
            }, 5000);
        }

        handlePredictionRecord()
        handleGetHistoryResult()
        if (stop === "dead" || cashout) {
            const timer = setTimeout(() => {
                setBet(false)
                setCashout(false)
                handlePredictionRecord()
                setStop(" ")
                setValues(Array(25).fill(null));
            }, 2000);

            // Clean up the timer to prevent multiple executions
            return () => clearTimeout(timer);
        }
    }, [bet, cashout, stop]);



    return (
        <>
            {/* header */}
            <header className='login-header Aviator-top-header  flex justify-between'>
                <div className="flex items-center">
                    <span className='icons icons-hover' onClick={() => navigate("/")}><FaArrowLeft className='white-color' /></span>
                    <h2 className="heading-h2 text-white text-xl font-bold ms-5">Mines</h2>
                </div>
                <div className="dashboard-bell-section">
                    <span>₹ {Number(balance).toFixed(2)}</span>
                    <span className='icons icons-hover' onClick={() => navigate("/recharge")}><IoWallet /></span>
                    <span className='icons icons-hover'>< FaQuestionCircle /></span>
                </div>
            </header>

            {/* history section
 */}
            <div className="aviator-history-section">
                <div className="head flex justify-between items-center" onClick={handleOpenHead}>
                    <h4 className="heading-h4 mr-2"><Link className='mr-3'>History</Link>

                        <span className={history[0]?.result <= 5 ? 'bg-blue span-radius ' : history[0]?.result <= 10 ? "bg-violet span-radius" : "bg-red span-radius"}  >x{history[0]?.result} </span>

                        <span className={history[1]?.result <= 5 ? 'bg-blue span-radius ' : history[1]?.result <= 10 ? "bg-violet span-radius" : "bg-red span-radius"}  >x{history[1]?.result} </span>

                        <span className={history[2]?.result <= 5 ? 'bg-blue span-radius ' : history[2]?.result <= 10 ? "bg-violet span-radius" : "bg-red span-radius"}  >x{history[2]?.result} </span>

                        <span className={history[3]?.result <= 5 ? 'bg-blue span-radius ' : history[3]?.result <= 10 ? "bg-violet span-radius" : "bg-red span-radius"}  >x{history[3]?.result} </span>


                    </h4>
                    <div className="icons cursor-pointer" >< MdKeyboardArrowDown /></div>
                </div>
                <div className={openHead ? "content active" : "content"} >

                    {history?.map((item, i) => (
                        <span className={item.result <= 5 ? 'bg-blue span-radius ' : item.result <= 10 ? "bg-violet span-radius" : "bg-red span-radius"} key={i}>x{item.result}

                        </span>
                    ))}


                </div>
            </div>

            <div className="container-section">
                <div className="shadow-section">
                    {/* {value === null ? <MdOutlineQuestionMark /> : value} */}
                    <div className="mines-game-section">
                        <div className="mines-game-box">
                            {values.map((value, index) => (
                                <button key={index} disabled={!bet || stop === "dead"}>
                                    <span id={value?.type?.name === "PiCircleDashedFill" ? 'dead' : stop === "dead" ? 'ques-bg-blue' : ""} className={bet ? value ? 'ques active' : 'ques ques-bg-blue' : 'ques'} onClick={() => handleClick(index)}>
                                        {stop === "dead" ? value ? value : <GoStarFill /> : (value === null ? <MdOutlineQuestionMark /> : value)}
                                    </span>

                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="flex justify-between items-center px-3">
                        <div className='w-[49%]'>
                            <TextField
                                id="standard-read-only-input"
                                label="Max win"
                                defaultValue={maxwin.toFixed(2)}
                                value={maxwin.toFixed(2)}

                                InputProps={{
                                    readOnly: true,
                                }}
                                className='w-[100%]'
                                variant="standard"
                            />
                        </div>
                        <FormControl className='w-[49%]' variant="standard" sx={{ m: 1, minWidth: 120 }}>


                            <InputLabel id="demo-simple-select-standard-label">Traps</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={traps}
                                onChange={handleChange}
                                label="Traps"
                                className='w-[100%]'
                            >

                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={7}>7</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    {/*  */}
                    {alertMessage &&
                        <Stack sx={{ width: '100%' }} spacing={2} className='mt-1'>
                            <Alert variant="filled" severity="error">
                                {alertMessage}
                            </Alert>
                        </Stack>
                    }
                    <div className="aviator-price-inpute">
                        <div className='input'>
                            <TextField
                                fullWidth
                                name="amount"
                                label="Amount"
                                id="amount"
                                type='number'
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                autoComplete="amount"
                            />

                        </div>

                        <div className='aviator-increase'>
                            <button onClick={decrease}><AiOutlineMinus /></button>
                            <button onClick={increease}><AiOutlinePlus /></button>
                        </div>
                    </div>


                    <div className='pl-3 mb-2 text-xs font-thin'>Delivery: <span>49.00</span>, fee: <span>1.00</span></div>

                    <div className="text-center p-3 pt-1">
                        {/* <button className='blue-btn' style={{ width: "100%", height: "47px" }} onClick={handleBet}><span>PLAY</span></button> */}
                        <button
                            className={bet ? (stop === "dead" || cashout ? "blue-btn bg-gray" : "blue-btn bg-green") : "blue-btn"}
                            disabled={stop === "dead" || cashout}
                            onClick={() => { bet ? handleCashout() : handleBet() }}
                            style={{ width: "100%", height: "47px" }}
                        >
                            <span>{bet ? "cashout" : "PLAY"}</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="aviator-record-section">
                <div className="aviator-record-head">
                    <div className="icons"><FaRegListAlt /></div>
                    <p>My Mines Record</p>
                </div>
                <div className="container-section mt-3">
                    <div className="shadow-section">
                        <div className="prediction-section-color">
                            {myPeriodRecord.map((item, i) => (

                                <div className="my-record-section" key={i}>
                                    <div className="id-section" onClick={() => handleOpenAllRecord(i)}>
                                        <h3 className="heading-h3 flex">  {item.date}
                                            {" "}
                                            <span className={item.win_loss === "win" ? "color-green mx-2" : "color-red mx-2"} >   {item.win_loss}</span>

                                            <span className={item.win_loss === "win" ? "color-green" : "color-red"} >

                                                <span className={item.win_loss === "wait" ? "d-none" : "d-block"}>
                                                    {item.win_loss === "win" ? "+" : "-"} {" "}
                                                    {(item.amount - item.amount * 0.02).toFixed(2)}
                                                </span>
                                            </span>
                                        </h3>
                                        {openAllRecord === i ? <span><IoIosArrowUp /></span> : <span><IoIosArrowDown /></span>}
                                    </div>
                                    <div className={openAllRecord === i ? "all-prediction-record active" : "all-prediction-record"}>
                                        <h3 className="heading-h3 ">Period Detail</h3>
                                        <ul>
                                            <li>
                                                <span>Traps</span>
                                                <span>{item.isend} </span>
                                            </li>
                                            <li>
                                                <span>Trap indexes</span>
                                                <span>{item.count}</span>
                                            </li>
                                            <li>
                                                <span>Opened indexes</span>
                                                <span>{item.count}</span>
                                            </li>
                                            <li>
                                                <span>Steps</span>
                                                <span>{item.count}</span>
                                            </li>
                                            <li>
                                                <span>Multi</span>
                                                <span>{item.count}</span>
                                            </li>
                                            <li>
                                                <span>Delivery</span>
                                                <span className='color-blue'>{(item.amount - item.amount * 0.02).toFixed(2)}</span>
                                            </li>
                                            <li>
                                                <span>Fee</span>
                                                <span className='color-blue'>{(item.amount * 0.02).toFixed(2)}</span>
                                            </li>



                                            {/* select end */}
                                            <li>
                                                <span>Status</span>
                                                {/* <span className='color-red' >{item.win_loss}</span> */}
                                                <span className={item.win_loss === "win" ? "color-green" : "color-red"} >{item.win_loss}</span>
                                            </li>


                                            <li>
                                                <span>Amount</span>
                                                <span className={item.win_loss === "wait" ? "d-none" : "d-block"}>
                                                    <span className={item.win_loss === "win" ? "color-green" : "color-red"} >
                                                        {item.win_loss === "win" ? "+" : "-"} {" "}
                                                        {item.total_amount - item.total_amount * 0.02}
                                                    </span>
                                                </span>
                                            </li>
                                            <li>
                                                <span>Create Time</span>
                                                <span >{item.date}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            ))}



                            <div className="wingo-pagination">
                                <div className="total-page">{starting}-{ending} of {totalPageRecord}</div>
                                <div className="arrow">
                                    <button className={starting < 10 ? 'left disable-pagination' : 'left'} onClick={() => { handleDecreasePagination(); handlePredictionRecord(); }} disabled={starting < 10}><MdKeyboardArrowLeft /></button>
                                    <button className={ending >= totalPageRecord ? 'right disable-pagination' : 'right'} onClick={() => { handleIncreasePagination(); handlePredictionRecord(); }} disabled={ending >= totalPageRecord}><MdKeyboardArrowRight /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* modal */}
            <Modal
                keepMounted
                open={opennew}
                onClose={handleCloseNew}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={styles}>
                    {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro, quibusdam.</p> */}
                    <div className="wingo-modal-box">
                        <div className="name-section gray">
                            <h3 className="heading-h3">
                                {/* Completed */}
                                {messages === "Your balance is low" ? "Failed" : "Completed "}
                            </h3>
                        </div>
                        <div className="modal-content">
                            <h5 className="heading-h5">{messages}</h5>
                            <div className="cancel-confirm">
                                <button className="ok-btn" onClick={() => { handleCloseNew(); handlePredictionRecord() }}>OK</button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default Mines
// Given data



