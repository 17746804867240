import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'

const RiskAgreement = () => {
    const location = useLocation()
    const navigate = useNavigate()
    return (
        <>
            {/* header */}
            <header className='login-header wingo-top-header  flex justify-between'>
                <div className="flex items-center">
                    <span className='icons icons-hover' onClick={() => navigate("/dashboard", {
                        state: location.pathname,
                    })}><FaArrowLeft className='white-color' /></span>
                    <h2 className="heading-h2 text-white text-xl font-bold ms-5">Risk Disclosure Agreement</h2>
                </div>
            </header>


            <div className="container-section mt-3">
                <h5 className="heading-h5 text-center font-bold text-base">Chapter 1.Booking/Collection Description</h5>
                <p className="text-sm mt-3">Prepayment Booking/Recycling Customer should read and understand the business content carefully before making prepayment bookings (prepayment lock price, payment settlement and shipment) /recovery or repurchase (prepayment lock price, shipping payment) before making prepayment bookings to cooe:</p>
                <p className="pt-5 text-sm">
                    1. Before making an appointment/restoring the prepayment business, the customer should complete the real name authentication in the mall and ensure that the name, ID number, bank account number, delivery address and other information filled in are true, accurate and valid; Otherwise, the user will be liable for the consequences of false information.
                </p>
                <p className="pt-5 text-sm">
                    2. Customers can order gold and silver products in advance at the shopping centre. Orders can be cancelled by 01:30 a.m. on the same Saturday. When the customer pays the end payment, the mall receives the final payment and arranges the delivery.
                </p>
                <p className="text-sm mt-5">If the customer does not pay the final pick-up by 01:30 a.m. on Saturday, the customer is deemed to have made the last offer before the inventory and the booking is cancelled.</p>
                <p className="text-sm mt-5">3. Customers can make an appointment to recycle gold and silver products purchased at the gold point. Pre-purchase recovery requires a credit margin and confirmation of actual possession of gold and silver products purchased from the mall. Customers can cancel their reservation at any time before 01:30 on Saturday and the credit mark will be refunded after deducting the increase or decrease in the value of the goods within the corresponding time.</p>
                <p className="text-sm mt-5">
                    If the customer fails to deliver the goods to a shopping mall or shopping center at the designated collection point by Saturday within the same week, or if the goods delivered do not meet the recycling standard test, the customer will be deemed to have cancelled the reservation recovery and will bear the logistics and testing costs.
                </p>
                <p className="text-sm mt-5">4. Counting time: Daily 01:30-05:30 for the mall warehouse inventory time. During the inventory period, the mall stops accepting advance payments for reservations/receipts.</p>
                <p className="text-sm mt-5">5. For further details, please refer to the Business Guidelines in the front page of the mall, Understanding cooe.</p>
                <h2 className="heading-h2 text-lg font-bold mt-8 text-center">Chapter 2 Reveals the business model of cooe</h2>
                <p className="text-sm mt-5">Booking/repurchase orders, the business model for clearing balance shipments, uncertainties such as potential benefits and potential risks to the value of its merchandise due to real-time fluctuations in the gold and silver market, and the extent to which booking/repo risk stake is understood for customer booking/repo risk, Risk control ability and understanding of related products have high requirements. Customer selects pre-payment booking/repurchase, fully informed on behalf of the customer and understand the risks of prepayments/repurchase business and agree to and accept cooe current and future relevant booking/repurchase business processes and management systems (collectively, the Process Systems) to develop, modify and publish. This Risk Disclosure (Disclosure) is intended to fully disclose to the Client the risk of the prepayment booking/repurchase business and is intended only to provide reference for the client to assess and determine its own risk tolerance. The risk disclosures described in this disclosure are for example only. All risk factors associated with cooe Advance Booking/Repurchase are not detailed. Customers should also carefully understand and understand other possible risk factors before starting or participating in cooe pre-payment booking/repurchase business. If the customer is not aware of or is not aware of this disclosure, they should consult cooe Customer Service or the relevant regional service provider in a timely manner. If the Customer ultimately clicks on Risk Disclosure, it is deemed that the Customer fully agrees and accepts the full contents of this disclosure.</p>
            </div>
        </>
    )
}

export default RiskAgreement
