import React, { useEffect, useState } from 'react'
import "./dashboard.css"
import Logo from "../../asstes/cooe_app_icon.png"
import { MdFileDownload } from "react-icons/md";
import { IoWallet } from "react-icons/io5";
import { FaBell } from "react-icons/fa";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RiMoneyDollarBoxFill } from "react-icons/ri";
import { FaRegCreditCard } from "react-icons/fa6";
import { GrNotes } from "react-icons/gr";
import { MdOutlineSecurity } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BiCommentError } from "react-icons/bi";
import { BiCommentDetail } from "react-icons/bi";
import axios from 'axios';
import { useAuthToken } from '../../context/authToken';
import Layout from '../../layout/Layout';

const Dashboard = () => {
    const [Id, setId] = useState()
    const [phone, setPhone] = useState()
    const [balance, setBalance] = useState()

    const location = useLocation()
    const [open, setOpen] = useState(false)
    const handleOpen = () => { setOpen(!open) }
    const [active, setActive] = useState(false)
    const handleActive = () => { setActive(!active) }

    const navigate = useNavigate()
    const [authToken, setAuthToken] = useAuthToken()

    // const dd = c4fff693f6e48dacf8bd753322c3410e
    const authHandle = async () => {
        try {
            const data = await axios.post("https://hopeforcurrency.com/api/getUserData", authToken)
            setId(data.data.user.id)
            console.log("money", data.data.user.balance)
            setPhone(data.data.user.phone)
            setBalance(data.data.user.balance)

        } catch (error) {
        }
    }

    useEffect(() => {
        authHandle()
        window.onload = function () {
            authHandle()
        }
    }, [authToken])

    const handleLogout = () => {
        setAuthToken({
            ...authToken,
            auth: ''
        })
        localStorage.removeItem('authToken')
        navigate("/login")
    }

    return (
        <Layout>
            <div className="dashboard-top-section">
                <div>
                    <img src={Logo} alt="" />
                    <h2 className='heading-h2'>Download App</h2>
                </div>
                <div className="icons">
                    <MdFileDownload />
                </div>
            </div>
            <header className='login-header  flex justify-between'>
                <div className="flex items-center">
                    <span className='icons cursor-pointer'></span>
                    <h2 className="heading-h2 text-white text-xl font-medium ms-5">Mine</h2>
                </div>
                <div className="dashboard-bell-section">
                    <span>₹ {balance}.00</span>
                    <span className='icons icons-hover' onClick={() => navigate("/recharge")}><IoWallet /></span>
                    <span className='icons icons-hover'><FaBell /></span>
                </div>
            </header>
            <div className="container-section">
                <div className="dashboard-section">
                    <p className="para"><b>ID:</b> {Id}</p>
                    <p className="para"><b>Mobile:</b> +91 {phone}</p>
                    {/* <p className="para"><b>Nick Name:</b> Member5PCY35R9 <Link>CHANGE</Link></p> */}
                    <div className="dashboard-icons-section">
                        <div >
                            <div className="icons  cursor-pointer" onClick={() => navigate("/recharge", {
                                state: location.pathname,
                            })}>
                                <IoWallet />
                            </div>
                            <span>Recharge</span>
                        </div>
                        <div>
                            <div className="icons  cursor-pointer" onClick={() => navigate("/withdrawal", {
                                state: location.pathname,
                            })}>
                                < RiMoneyDollarBoxFill />
                            </div>
                            <span>Withdraw</span>
                        </div>
                        <div>
                            <div className="icons  cursor-pointer" onClick={() => navigate("/transactions", {
                                state: location.pathname,
                            })}>
                                <GrNotes />
                            </div>
                            <span>Transactions</span>
                        </div>
                        <div>
                            <div className="icons  cursor-pointer" onClick={() => navigate("/add-bank-card", {
                                state: location.pathname,
                            })}>
                                <FaRegCreditCard />
                            </div>
                            <span>Bank card</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-section">
                <div className="bottom-dashboard-section">
                    <div className="account-section">
                        <div className="account-box" onClick={handleOpen}>
                            <div className='box'>
                                <div className="icons">
                                    < MdOutlineSecurity />
                                </div>
                                <div className='flex flex-col'>
                                    <span>Account Security</span>
                                    <span className={open ? "open active  mt-4" : "open mt-4"} onClick={() => navigate('/reset-password')}>Reset Password</span>
                                </div>
                            </div>
                            <div className="icons">
                                <MdKeyboardArrowDown />
                            </div>
                        </div>
                    </div>
                    <div className="account-section">
                        <div className="account-box">
                            <div className='box'>
                                <div className="icons">
                                    <BiCommentDetail />
                                </div>
                                <span>Complaints & Suggestion</span>
                            </div>

                        </div>
                    </div>
                    <div className="account-section">
                        <div className="account-box" onClick={handleActive}>
                            <div className='box'>
                                <div className="icons">
                                    < BiCommentError />
                                </div>
                                <div className='flex flex-col'>
                                    <span>About</span>
                                    <div className={active ? "open active  mt-4" : "open mt-4"}>
                                        <div className='flex flex-col'>
                                            <span className="" onClick={() => navigate('/privacy-policy')}>Privacy Policy</span>
                                            <span className='mt-4' onClick={() => navigate('/riskAgreement')}>Risk Disclosure  Agreement</span>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="icons">
                                <MdKeyboardArrowDown />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="logout-button text-center">
                <button type="submit" className='logout-btn' onClick={handleLogout}>LOGOUT </button>
            </div>

        </Layout>
    )
}

export default Dashboard
