import React, { Fragment, useEffect, useState } from 'react'
import "./luckyhit.css"
import { IoWallet } from 'react-icons/io5'
import { FaQuestionCircle } from "react-icons/fa";
import { FaArrowLeft } from 'react-icons/fa6';
import TextField from '@mui/material/TextField';
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import RedDot from "../../asstes/red-dot.png"
import BlackDot from "../../asstes/black-dot.png"
import LuckyHitRed from "../../asstes/luckyhit_red_avatar.png"
import LuckyHitBlack from "../../asstes/luckyhit_black_avatar.png"
import LuckyHitVS from "../../asstes/luckyhit_vs.png"
import LuckyHitPoker from "../../asstes/poker_back.png"

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import LuckyHitRecord from './LuckHitRecord';
import axios from 'axios';
import { useAuthToken } from '../../context/authToken';
import { FaRegListAlt } from "react-icons/fa";

import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import Modal from '@mui/material/Modal';
import { useMoney } from '../../context/money';
const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 170,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,

};

const LuckyHit = () => {
    const navigate = useNavigate()
    const [openHead, setOpenHead] = useState(false)
    const [amount, setAmount] = useState(50)
    const [starting, setStarting] = useState(1)
    const [authToken, setAuthToken] = useAuthToken()
    const [ending, setEnding] = useState(10)
    const [history, setHistory] = useState([])
    const [betColor, setBetColor] = useState(null)
    const [currentPeriodId, setCurrentPeriodId] = useState('')
    const handleOpenHead = () => setOpenHead(!openHead)
    const [progress, setProgress] = useState(100);
    const [opennew, setOpenNew] = React.useState(false);
    const handleOpenNew = () => setOpenNew(true);
    const handleCloseNew = () => setOpenNew(false);
    const [black_side, setBlackSite] = useState([])
    const [red_side, setRedSite] = useState([])
    const [redImg, setRedImg] = useState([])
    const [blackImg, setBlckImg] = useState([])
    const [historyHandled, setHistoryHandled] = useState(false);
    const [pro, setPro] = useState(false);
    const [record, setRecord] = useState(false)
    const [myPeriodRecord, setMyPeriodRecod] = useState([])
    const [totalPageRecord, setTotalPageRecord] = useState(Number)
    const [messages, setMessages] = useState("")
    const [balance, setBalance] = useState('');
    const increease = () => {
        setAmount(Number(amount) + 10)
    }
    const decrease = () => {
        if (amount >= 20) {
            setAmount(Number(amount) - 10)
        }
    }
    const [openAllRecord, setOpenAllRecord] = useState(null)


    const handleOpenAllRecord = (i) => {
        if (openAllRecord === i) {
            return setOpenAllRecord(null)
        }
        setOpenAllRecord(i)
    }

    //start record
    const handleIncreasePagination = () => {
        setStarting(Number(starting) + 10)
        setEnding(Number(ending) + 10)

    }
    const handleDecreasePagination = () => {
        setStarting(Number(starting) - 10)
        setEnding(Number(ending) - 10)
    }    // my prediction record
    const authHandle = async () => {
        try {
            const data = await axios.post("https://hopeforcurrency.com/api/getUserData", authToken)
            setBalance(data.data.user.balance)
        } catch (error) {
        }
    }
    const handlePredictionRecord = async () => {
        try {
            const data = await axios.post("https://hopeforcurrency.com/api/getuserresult_luckyhit", { auth: authToken.auth, starting: starting, ending: ending })
            setMyPeriodRecod(data?.data?.results)

            setTotalPageRecord(data?.data?.total_rows)
            authHandle()
        } catch (error) {

        }
    }
    //end record
    const handlePrediction = async () => {
        try {
            const data = await axios.post("https://hopeforcurrency.com/api/luckyhit_Period")
            setCurrentPeriodId(data?.data?.period_id)

        } catch (error) {

        }
    }

    useEffect(() => {
        authHandle()
        if (betColor !== null) {
            submitBet();
            handleOpenNew()
        }
    }, [betColor]); // Trigger effect whenever betColor changes

    const submitBet = async () => {

        try {

            const data = await axios.post("https://hopeforcurrency.com/api/luckyhit_bet", {
                auth: authToken.auth,
                amount: amount,
                period_id: currentPeriodId,
                bet: betColor
            })
            setMessages(data?.data?.message)
            authHandle()
        } catch (error) {

        }

    }
    const handleHistory = async () => {
        try {
            const data = await axios.post("https://hopeforcurrency.com/api/getresult_luckyhit", { starting: starting, ending: ending })

            setRedSite(data?.data?.results[0]?.red_side)
            setBlackSite(data?.data?.results[0]?.black_side)
            setHistory(data?.data?.results)
        } catch (error) {

        }
    }
    const handleButtonClick = (color) => {
        // Set the betColor state first
        setBetColor(color);
        // Then call the handleBet function
    };


    useEffect(() => {
        handleHistory()
        handlePredictionRecord()
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 0) {
                    return 0;
                }
                const diff = 1; // Set diff to a constant value of 1
                return Math.max(oldProgress - diff, 0); // Ensure the progress doesn't go below 0
            });
        }, 500); // 1.5 seconds

        return () => {
            clearInterval(timer);
        };
    }, []);
    // console.log("objec gggt", black_side)

    useEffect(() => {

        if (progress === 0 && !historyHandled) {
            const timer = setTimeout(() => {
                handleHistory();
                setHistoryHandled(true); // Set the flag to true after calling handleHistory
            }, 1000);
            // Clean up the timer to prevent multiple executions
            return () => clearTimeout(timer);
        }
        if (progress === 0 && !record) {
            const timer = setTimeout(() => {
                handlePredictionRecord();
                setRecord(true); // Set the flag to true after calling handleHistory
            }, 3000);

            // Clean up the timer to prevent multiple executions
            return () => clearTimeout(timer);
        }
    }, [progress, historyHandled]);
    if (progress === 0) {
        setTimeout(() => {
            setProgress(100);

            setHistoryHandled(false);
            setRecord(false);
            setPro(true);
        }, 10000);
    }

    // setBlckImg(blackarr)
    useEffect(() => {
        handlePrediction()
        async function processData(black_side, red_side) {
            try {
                const blackarr = JSON.parse(black_side);
                const redarr = red_side ? JSON.parse(red_side) : null;
                setBlckImg(blackarr)
                setRedImg(redarr)
            } catch (error) {
                console.error("Error processing data:", error);
            }
        }

        // Assuming black_side and red_side are Promises that resolve to JSON strings
        async function fetchAndProcessData() {
            try {


                // Call processData function with the fetched data
                await processData(black_side, red_side);
            } catch (error) {
                console.error("Error fetching and processing data:", error);
            }
        }
        fetchAndProcessData();
    }, [black_side, red_side, messages])





    return (
        <>
            {/* header */}
            <header className='login-header Aviator-top-header  flex justify-between'>
                <div className="flex items-center">
                    <span className='icons icons-hover' onClick={() => navigate("/")}><FaArrowLeft className='white-color' /></span>
                    <h2 className="heading-h2 text-white text-xl font-bold ms-5">Lucky Hit</h2>
                </div>
                <div className="dashboard-bell-section">
                    <span>₹ {Number(balance).toFixed(2)}</span>
                    <span className='icons icons-hover' onClick={() => navigate("/recharge")}><IoWallet /></span>
                    <span className='icons icons-hover'>< FaQuestionCircle /></span>
                </div>
            </header>

            {/* history section
 */}
            <div className="luckyhit-history-section">
                <div className="head flex justify-between items-center" onClick={handleOpenHead}>
                    <h4 className="heading-h4 mr-2"><Link className='mr-3'>History</Link>
                        {history[0]?.result === "black" ? <img src={BlackDot} alt="" /> : history[0]?.result === "red" ? <img src={RedDot} alt="" /> : <img className='brown' src={RedDot} alt="" />}
                        {history[1]?.result === "black" ? <img src={BlackDot} alt="" /> : history[1]?.result === "red" ? <img src={RedDot} alt="" /> : <img className='brown' src={RedDot} alt="" />}
                        {history[2]?.result === "black" ? <img src={BlackDot} alt="" /> : history[2]?.result === "red" ? <img src={RedDot} alt="" /> : <img className='brown' src={RedDot} alt="" />}
                        {history[3]?.result === "black" ? <img src={BlackDot} alt="" /> : history[3]?.result === "red" ? <img src={RedDot} alt="" /> : <img className='brown' src={RedDot} alt="" />}
                        {history[4]?.result === "black" ? <img src={BlackDot} alt="" /> : history[4]?.result === "red" ? <img src={RedDot} alt="" /> : <img className='brown' src={RedDot} alt="" />}
                        {history[5]?.result === "black" ? <img src={BlackDot} alt="" /> : history[5]?.result === "red" ? <img src={RedDot} alt="" /> : <img className='brown' src={RedDot} alt="" />}
                        {/* {history[4]?.result === "black" ? <img src={Bl  ackDot} alt="" /> : history[4]?.result === "red" ? <img src={RedDot} alt="" /> : <img className='brown' src={RedDot} alt="" />}
                        {history[5]?.result === "black" ? <img src={BlackDot} alt="" /> : history[5]?.result === "red" ? <img src={RedDot} alt="" /> : <img className='brown' src={RedDot} alt="" />}
                        {history[6]?.result === "black" ? <img src={BlackDot} alt="" /> : history[6]?.result === "red" ? <img src={RedDot} alt="" /> : <img className='brown' src={RedDot} alt="" />} */}


                    </h4>
                    <div className="icons cursor-pointer" >< MdKeyboardArrowDown /></div>
                </div>
                <div className={openHead ? "content active" : "content"} >
                    {history.map((item, i) => (
                        <Fragment key={i}>
                            {item.result === "black" ? <img src={BlackDot} alt="" /> : item.result === "red" ? <img src={RedDot} alt="" /> : <img className='brown' src={RedDot} alt="" />}
                        </Fragment>
                    ))}




                </div>
            </div>

            <div className="container-section">
                <div className="shadow-section">
                    <div className="luckhyhit-game-section">
                        <h3 className="heading-h3">Period: {currentPeriodId}</h3>
                        <div className="lucky-hit-game-container mt-2">
                            <div className="lucky-hit-head">
                                <div className="lucky-hit">
                                    <img src={LuckyHitBlack} alt="" />
                                </div>
                                <div className="lucky-hit-black">

                                    <img src={blackImg && progress === 0 ? `img/${blackImg[0]}.jpg` : LuckyHitPoker} alt="" />
                                    <img src={blackImg && progress === 0 ? `img/${blackImg[1]}.jpg` : LuckyHitPoker} alt="" />
                                    <img src={blackImg && progress === 0 ? `img/${blackImg[2]}.jpg` : LuckyHitPoker} alt="" />

                                    {/* <img src={LuckyHitPoker} alt="" /> */}
                                </div>

                                <div className="lucky-hit">
                                    <img src={LuckyHitVS} alt="" />
                                </div>
                                <div className="lucky-hit-black">
                                    <img src={blackImg && progress === 0 ? `img/${redImg[0]}.jpg` : LuckyHitPoker} alt="" />
                                    <img src={blackImg && progress === 0 ? `img/${redImg[1]}.jpg` : LuckyHitPoker} alt="" />
                                    <img src={blackImg && progress === 0 ? `img/${redImg[2]}.jpg` : LuckyHitPoker} alt="" />
                                </div>
                                <div className="lucky-hit">
                                    <img src={LuckyHitRed} alt="" />
                                </div>
                            </div>
                            <div className="lucky-hit-bottom">
                                <div className='lucky-hit-number'>
                                    <h3>
                                        <span>
                                            461740
                                        </span>
                                        Black
                                    </h3>
                                    <h3>
                                        <span>
                                            461740
                                        </span>
                                        Lucky Hit
                                    </h3>
                                    <h3>
                                        <span>
                                            461740
                                        </span>
                                        Red
                                    </h3>
                                </div>
                                <div className="lucky-hit-progress">
                                    <div className={progress <= 0 ? "d-none" : "d-block"}>
                                        <Box sx={{ width: '100%' }}>
                                            <LinearProgress variant="determinate" value={progress} />
                                        </Box>
                                    </div>
                                    <div className={progress <= 0 ? "d-block text-center" : "d-none"}>
                                        <Link className='color-blue text-sm font-bold text-center '> {history && history ? history[0]?.result : "Now Waiting"} </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  */}
                    <div className="aviator-price-inpute">
                        <div className='input'>
                            <TextField
                                fullWidth
                                name="amount"
                                label="Amount"
                                id="amount"
                                type='number'
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                autoComplete="amount"
                            />
                        </div>

                        <div className='aviator-increase'>
                            <button onClick={decrease}><AiOutlineMinus /></button>
                            <button onClick={increease}><AiOutlinePlus /></button>
                        </div>
                    </div>
                    <div className="luckyhit-select-button">
                        <button className='bg-black text-white' onClick={() => handleButtonClick("black")}>BLACK</button>
                        <button className='bg-red text-white' onClick={() => handleButtonClick("red")}>RED</button>
                    </div>
                    <div className="text-center p-3 pt-1">
                        <button className='blue-btn brown-btn' style={{ width: "100%", height: "47px" }} onClick={() => handleButtonClick("lucky hit")}><span>LUCKY HIT</span></button>
                    </div>
                </div>
            </div>

            {/*start result */}
            <div className="aviator-record-section">
                <div className="aviator-record-head">
                    <div className="icons"><FaRegListAlt /></div>
                    <p>My Lucky Hit Record</p>
                </div>
                <div className="container-section mt-3">
                    <div className="shadow-section">
                        <div className="prediction-section-color">
                            {myPeriodRecord.map((item, i) => (

                                <div className="my-record-section" key={i}>
                                    <div className="id-section" onClick={() => handleOpenAllRecord(i)}>
                                        <h3 className="heading-h3 flex">  {item.period_id}
                                            {" "}
                                            <span className={item.win_loss === "win" ? "color-green mx-2" : "color-red mx-2"} >   {item.win_loss}</span>
                                            <span className={item.win_loss === "win" ? "color-green" : "color-red"} >

                                                <span className={item.win_loss === "wait" ? "d-none" : "d-block"}>
                                                    {item.win_loss === "win" ? "+" : item.result === null ? "" : "-"} {" "}
                                                    {item.result === null ? "wait" : (item.amount - item.amount * 0.02).toFixed()}

                                                    {/* {item.amount - item.amount * 0.02} */}
                                                </span>

                                            </span>
                                        </h3>
                                        {openAllRecord === i ? <span><IoIosArrowUp /></span> : <span><IoIosArrowDown /></span>}
                                    </div>
                                    <div className={openAllRecord === i ? "all-prediction-record active" : "all-prediction-record"}>
                                        <h3 className="heading-h3 ">Period Detail</h3>
                                        <ul>
                                            <li>
                                                <span>Period</span>
                                                <span> {item.period_id}</span>
                                            </li>
                                            <li>
                                                <span>Contract Money</span>
                                                <span>{item.amount} </span>
                                            </li>

                                            <li>
                                                <span>Fee</span>
                                                <span className='color-blue'>{item.amount * 0.02}</span>
                                            </li>

                                            <li className=''>
                                                <span>Result</span>
                                                <span className={item.result === "black" ? "color-black" : (item.result === "red") ? "color-red" : "color-brown"}>{item.result === null ? "wait" : item.result}</span>
                                            </li>
                                            <li className=''>
                                                <span>Select</span>
                                                <span className={item.bet === "black" ? "color-black" : (item.bet === "red") ? "color-red" : "color-brown"}>{item.bet}</span>
                                            </li>
                                            <li>
                                                <span>Status</span>
                                                {/* <span className='color-red' >{item.win_loss}</span> */}
                                                <span className={item.win_loss === "win" ? "color-green" : "color-red"} >{item.win_loss === null ? 'null' : item.win_loss}</span>
                                            </li>
                                            <li>
                                                <span>Amount</span>
                                                <span className={item.win_loss === "wait" ? "d-none" : "d-block"}>
                                                    <span className={item.win_loss === "win" ? "color-green" : "color-red"} >
                                                        {item.win_loss === "win" ? "+" : "-"} {" "}
                                                        {item.amount}
                                                    </span>
                                                </span>
                                            </li>
                                            <li>
                                                <span>Create Time</span>
                                                <span >{item.date}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            ))}



                            <div className="wingo-pagination">
                                <div className="total-page">{starting}-{ending} of {totalPageRecord}</div>
                                <div className="arrow">
                                    <button className={starting < 10 ? 'left disable-pagination' : 'left'} onClick={() => { handleDecreasePagination(); handlePredictionRecord(); }} disabled={starting < 10}><MdKeyboardArrowLeft /></button>
                                    <button className={ending >= totalPageRecord ? 'right disable-pagination' : 'right'} onClick={() => { handleIncreasePagination(); handlePredictionRecord(); }} disabled={ending >= totalPageRecord}><MdKeyboardArrowRight /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*end result */}

            {/* modal */}
            <Modal
                keepMounted
                open={opennew}
                onClose={handleCloseNew}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={styles}>
                    {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro, quibusdam.</p> */}
                    <div className="wingo-modal-box">
                        <div className="name-section gray">
                            <h3 className="heading-h3">
                                {/* Completed */}
                                {messages === "Your balance is low" ? "Failed" : "Completed "}
                            </h3>
                        </div>
                        <div className="modal-content">
                            <h5 className="heading-h5">{messages}</h5>
                            <div className="cancel-confirm">
                                <button className="ok-btn" onClick={() => { handleCloseNew(); handlePredictionRecord() }}>OK</button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default LuckyHit
// Given data



