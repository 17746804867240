import React, { useState, useContext, createContext, useEffect } from "react";

const MoneyContext = createContext();

const MoneyProvider = ({ children }) => {
    const [money, setMoney] = useState([]);

    useEffect(() => {
        // Load data from localStorage when the component mounts
        const existingMoneyItem = localStorage.getItem("money");
        if (existingMoneyItem) {
            setMoney(JSON.parse(existingMoneyItem));
        }
    }, []);

    // Function to add or update money data
    const updateMoneyData = (newData) => {
        // Check if the data already exists in the state
        const existingIndex = money.findIndex(item => item?.id === newData?.id);
        if (existingIndex !== -1) {
            // If it exists, update the existing data
            const updatedMoney = [...money];
            updatedMoney[existingIndex] = newData;
            setMoney(updatedMoney);
        } else {
            // If it doesn't exist, add the new data
            setMoney(prevMoney => {
                // Ensure newData is not already in prevMoney before adding
                const newDataExists = prevMoney.some(item => item.id === newData.id);
                return newDataExists ? prevMoney : [...prevMoney, newData];
            });
        }
        // Update localStorage
        localStorage.setItem("money", JSON.stringify(money));
    };

    return (
        <MoneyContext.Provider value={{ money, updateMoneyData }}>
            {children}
        </MoneyContext.Provider>
    );
};

const useMoney = () => useContext(MoneyContext);

export { useMoney, MoneyProvider };
