import React, { useState } from 'react';
import './App.css';
import "./darkmode.css"
import { Routes, Route } from "react-router-dom"
import HomePage from './component/home/HomePage';
import Footer from './layout/Footer';
import Login from './component/user/Login';
import Register from './component/user/Register';
import Dashboard from './component/user/Dashboard';
import ResetPassword from './component/user/ResetPassword';
import Wingo from './component/wingo/Wingo';
import PrivateRoute from './component/route/PrivateRoute';
import Promotion from './component/wingo/promotion/Promotion';
import Recharge from './component/wingo/promotion/Recharge';
import Withdrawal from './component/wingo/Withdrawal';
import AddBank from './component/wingo/AddBank';
import Aviator from './component/aviator/Aviator';
import LuckyHit from './component/lucky-hit/LuckyHit';
import Transaction from './component/wingo/Transaction';
import RechargeRecord from './component/wingo/RechargeRecord';
import Mines from './component/mines/Mines';
import "./responsive.css"
import RiskAgreement from './component/about/RiskAgreement';
import PrivacyPolicy from './component/about/privacyPolicy';
import { useTheme } from './context/ThemeProvider';
import Demo from './component/aviator/Demo';
function App({ toggleMode, mode }) {
  const { theme, toggleTheme } = useTheme();

  // Function to toggle mode

  return (
    <>

      <div className={`application-main-section ${theme}`}>

        <div className="main-second-container">
          <Routes>
            {/* <Route path='/' element={<Demo />} /> */}
            <Route path='/' element={<HomePage />} />

            {/* private route */}
            <Route path="/" element={<PrivateRoute />}>
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='/wingo' element={<Wingo />} />
              <Route path='/promotion' element={<Promotion />} />
              <Route path='/recharge' element={<Recharge />} />
              <Route path='/withdrawal' element={<Withdrawal />} />
              <Route path='/add-bank-card' element={<AddBank />} />
              <Route path='/transactions' element={<Transaction />} />
              <Route path='/recharge-record' element={<RechargeRecord />} />


              {/* aviator */}
              <Route path='/aviator' element={<Aviator />} />
              {/* lucky hit */}
              <Route path='/luckyhit' element={<LuckyHit />} />
              {/* mines */}
              <Route path='/mines' element={<Mines />} />
            </Route>

            <Route path='/riskAgreement' element={<RiskAgreement />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />


            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/reset-password' element={<ResetPassword />} />
          </Routes>
        </div>

      </div>
    </>
  );
}

export default App;
