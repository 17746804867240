import { useState, useEffect, useContext, createContext } from "react";
import axios from "axios";

const AuthContext = createContext();
const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState({
        auth: "",
    });
    //default axios
    axios.defaults.headers.common["Authorization"] = authToken?.auth;
    useEffect(() => {
        const data = localStorage.getItem("authToken");
        if (data) {
            const parseData = JSON.parse(data);
            // console.log("parsdata", parseData.authToken)
            setAuthToken({
                ...authToken,

                auth: parseData?.authToken
            });
            // console.log("tooo", parseData.user.tokens[0].token)
        }
        //eslint-disable-next-line
    }, []);
    return (
        <AuthContext.Provider value={[authToken, setAuthToken]}>
            {children}
        </AuthContext.Provider>
    );
};

// custom hook
const useAuthToken = () => useContext(AuthContext);

export { useAuthToken, AuthProvider };