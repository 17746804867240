import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./spinner.css"
const Spinner = ({ path = "login" }) => {
    const [count, setCount] = useState(3);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const interval = setInterval(() => {
            setCount((prevValue) => --prevValue);
        }, 3000);
        count === 0 &&
            navigate(`/${path}`, {
                state: location.pathname,
            });
        return () => clearInterval(interval);
    }, [count, navigate, location, path]);
    return (
        <>
            <div
                className="flex pre-loader flex-col justify-center items-center"
                style={{ height: "95vh", background: "transparent" }}
            >
                {/* <h1 className="Text-center">{count}  </h1> */}

                <div className='spinner-section'>
                    <div class="main-cont">
                        <h2 className="mb-3">Loading</h2>
                        <div class="line-progress-bar"></div>
                    </div>
                </div>
                {/* </div> */}
            </div>
        </>
    );
};

export default Spinner;