import React, { useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa6';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from "../../layout/Layout"
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import axios from 'axios';
import { useAuthToken } from '../../context/authToken';

const AddBank = () => {
    const [formData, setFormData] = useState({ name: '', phone: '', state: '', upi: '', bank_name: '', bank_ifsc: '', bank_number: '', address: '' });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate()
    const location = useLocation()
    const [authToken, setAuthToken] = useAuthToken()

    const handleInputChange = async (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        try {
            const data = await axios.post("https://hopeforcurrency.com/api/setbank", {
                bank_name: formData.bank_name,

                bank_ifsc: formData.bank_ifsc,
                bank_number: formData.bank_number,
                address: formData.address,
                auth: authToken.auth
            })

            // $validator = Validator::make($request->all(), [
            //     'auth' => 'required',
            //     'bank_name' => 'required',
            //     'bank_ifsc' => 'required',
            //     'bank_number' => 'required',
            //     'address' => 'required',
            // ]);
            console.log("daa", data)
        } catch (error) {

        }

    }
    return (
        <Layout>
            <header className='login-header wingo-top-header py-0  flex justify-between'>
                <div className="flex items-center py-1">
                    <span className='icons icons-hover' onClick={() => navigate("/dashboard", {
                        state: location.pathname,
                    })}><FaArrowLeft className='white-color' /></span>
                    <h2 className="heading-h2 text-white text-xl font-bold ms-5">Add Bank Card</h2>
                </div>
            </header>

            <div className="container-section">

                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >


                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2} >

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="name"
                                    label="Actual Name"
                                    name="name"
                                    autoComplete="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="bank_ifsc"
                                    label="IFSC Code"
                                    value={formData.bank_ifsc}
                                    onChange={handleInputChange}

                                    autoComplete="bank_ifsc"

                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="bank_name"
                                    label="Bank Name"
                                    value={formData.bank_name}
                                    onChange={handleInputChange}
                                    autoComplete="bank"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="bank_number"
                                    label="Account Number"

                                    autoComplete="bank_number"
                                    value={formData.bank_number}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="state"
                                    label="State/Territory"
                                    value={formData.state}
                                    onChange={handleInputChange}
                                    autoComplete="state"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="address"
                                    label="Address"
                                    value={formData.address}
                                    onChange={handleInputChange}
                                    autoComplete="address"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="phone"
                                    label="Mobile Number"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    type="number"
                                    autoComplete="phone"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField

                                    fullWidth
                                    name="upi"
                                    label="UPI Account"
                                    value={formData.upi}
                                    onChange={handleInputChange}
                                    autoComplete="upi"
                                />
                            </Grid>



                        </Grid>


                    </Box>
                </Box>
                <div className=" text-center pb-14">
                    <button type="submit" onClick={handleSubmit} className='blue-btn mt-5' >Continue</button>
                </div>


            </div>


        </Layout >
    )
}

export default AddBank
