import React from 'react'
import { Link } from 'react-router-dom'
import { IoLogoGameControllerB } from "react-icons/io";
import { BsEmojiSmileUpsideDownFill } from "react-icons/bs";
import { IoMdPricetag } from "react-icons/io";
const Footer = () => {
    return (
        <>
            <div className="footer-section">
                <footer>
                    <Link className="footer-box" to={"/"}>
                        <div className="icons">
                            <IoLogoGameControllerB />
                        </div>
                        <span> Home </span>
                    </Link>
                    <Link className="footer-box" to={"/promotion"}>
                        <div className="icons promos">
                            <IoMdPricetag />
                        </div>
                        <span> Promotion </span>
                    </Link>
                    <Link className="footer-box" to={"/dashboard"}>
                        <div className="icons">
                            <BsEmojiSmileUpsideDownFill />
                        </div>
                        <span> Mine </span>
                    </Link>
                </footer>
            </div>
        </>
    )
}

export default Footer
