import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import { GoDotFill } from "react-icons/go";
const PrivacyPolicy = () => {
    const location = useLocation()
    const navigate = useNavigate()
    return (
        <>
            {/* header */}
            <header className='login-header wingo-top-header  flex justify-between'>
                <div className="flex items-center">
                    <span className='icons icons-hover' onClick={() => navigate("/dashboard", {
                        state: location.pathname,
                    })}><FaArrowLeft className='white-color' /></span>
                    <h2 className="heading-h2 text-white text-xl font-bold ms-5">Privacy Policy</h2>
                </div>
            </header>

            <div className="container-section dark:bg-black pt-4">
                <p className="text-sm">This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
                <h2 className="text-4xl font-bold mt-5">Interpretation and Definitions</h2>
                <h4 className="text-2xl font-bold mt-3">Interpretation</h4>
                <p className="text-sm mt-5">The words of which the initial letter is capitalized have meanings defined under the following conditions.</p>
                <p className="text-sm mt-5">The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                <h4 className="text-2xl font-bold mt-3">Definitions</h4>
                <p className="text-sm mt-1">For the purposes of this Privacy Policy:</p>

                <div>
                    <div className='flex mt-4 text-sm '><span className='mr-1'><GoDotFill /></span> <p><strong className='font-bold'>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p></div>
                    <div className='flex mt-4 text-sm '><span className='mr-1'><GoDotFill /></span> <p><strong className='font-bold'>Company</strong>  (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to cooe.</p></div>
                    <div className='flex mt-4 text-sm '><span className='mr-1'><GoDotFill /></span> <p><strong className='font-bold'>Affiliate </strong>means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority. </p></div>
                    <div className='flex  '><span className='mr-1'><GoDotFill /></span> <p><strong className='font-bold'>Account</strong>  means a unique account created for You to access our Service or parts of our Service.</p></div>
                    <div className='flex text-sm '><span className='mr-1'><GoDotFill /></span> <p><strong className='font-bold'>Website</strong> refers to cooe, accessible from https://cooe.in </p></div>
                    <div className='flex text-sm '><span className='mr-1'><GoDotFill /></span> <p><strong className='font-bold'>Service</strong>  refers to the Website.</p></div>
                    <div className='flex text-sm '><span className='mr-1'><GoDotFill /></span> <p><strong className='font-bold'>Country</strong>  refers to: Uttar Pradesh, India</p></div>
                    <div className='flex mt-4 text-sm '><span className='mr-1'><GoDotFill /></span> <p><strong className='font-bold'>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used. </p></div>
                    <div className='flex mt-4 text-sm '><span className='mr-1'><GoDotFill /></span> <p><strong className='font-bold'>Third-party Social Media Service </strong> refers to any website or any social network website through which a User can log in or create an account to use the Service.</p></div>
                    <div className='flex  text-sm '><span className='mr-1'><GoDotFill /></span> <p><strong className='font-bold'>Personal Data</strong>  is any information that relates to an identified or identifiable individual.</p></div>
                    <div className='flex mt-4 text-sm '><span className='mr-1'><GoDotFill /></span> <p><strong className='font-bold'>Cookies</strong>  are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p></div>
                    <div className='flex mt-4 text-sm '><span className='mr-1'><GoDotFill /></span> <p><strong className='font-bold'>Device </strong>means any device that can access the Service such as a computer, a cellphone or a digital tablet. </p></div>
                    <div className='flex mt-4 text-sm '><span className='mr-1'><GoDotFill /></span> <p><strong className='font-bold'>Usage Data</strong>  refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p></div>

                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy
