import React, { useEffect, useRef, useState } from 'react'
import "./aviator.css"
import { IoWallet } from 'react-icons/io5'
import { FaQuestionCircle } from "react-icons/fa";
import { FaArrowLeft } from 'react-icons/fa6';
import TextField from '@mui/material/TextField';
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import Plane from "../../asstes/aviator_plane.png"
import AviatorRecord from './AviatorRecord';
import RotateImg from "../../asstes/aviator_waiting_fly.png"
import axios from 'axios';
import { useAuthToken } from '../../context/authToken';
import { FaRegListAlt } from "react-icons/fa";
import { BsFillPatchCheckFill } from "react-icons/bs";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import "./demo.css"
const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 170,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,

};
const Aviator = () => {
    const navigate = useNavigate()
    const [openHead, setOpenHead] = useState(false)
    const [openCashed, setOpencashed] = useState(false)
    const [amount, setAmount] = useState(50)
    const [balance, setBalance] = useState('');
    const handleOpenHead = () => setOpenHead(!openHead)
    const handleOpenCashed = () => setOpencashed(!openCashed)
    const [history, setHistory] = useState([])
    const [numberX, setNumberX] = useState(0)
    const [authToken, setAuthToken] = useAuthToken()
    const [period_id, setPeriod_id] = useState('')
    const [bet, setBet] = useState(false)
    const [cashout, setCashout] = useState(false)
    const [loading, setLoading] = useState(false)
    const [waitResult, setWaitResult] = useState(false);
    const [crassValue, setCrassValue] = useState(null);
    const [starting, setStarting] = useState(1)
    const [ending, setEnding] = useState(10)
    const [userResult, setUserResult] = useState([])
    const [totalPageRecord, setTotalPageRecord] = useState(Number)
    // popup
    const [opennew, setOpenNew] = React.useState(false);
    const handleOpenNew = () => setOpenNew(true);
    const handleCloseNew = () => setOpenNew(false);
    const [messages, setMessages] = useState("")

    const increease = () => {
        setAmount(Number(amount) + 10)
    }
    const decrease = () => {
        if (amount >= 20) {
            setAmount(Number(amount) - 10)
        }
    }


    const [fly, setFly] = useState(true); // State to control the flying animation
    const [flyfirst, setFlySetFlyFirst] = useState(true); // State to control the flying animation

    const authHandle = async () => {
        try {
            const data = await axios.post("https://hopeforcurrency.com/api/getUserData", authToken)
            setBalance(data.data.user.balance)
        } catch (error) {
        }
    }


    const handleGetHistoryResult = async () => {

        try {
            if (loading) return; // Prevent multiple clicks
            setLoading(true);
            const data = await axios.post("https://hopeforcurrency.com/api/aviator_getresult")
            setHistory(data?.data?.results)
            // console.log("object hihji", data?.data?.results            )
            setLoading(false)
        } catch (error) {

        }
    }
    const handleAviatorResult = async () => {
        try {
            if (waitResult) return; // Prevent multiple clicks
            setWaitResult(true);
            const response = await axios.get("https://hopeforcurrency.com/api/aviator_result");
            console.log("Response:", response);
            authHandle()
            if (response && response.data && response.data.message) {
                setCrassValue(response.data.message);
            } else {
                // Handle the case where the response doesn't contain the expected data
                console.error("Unexpected response:", response);
            }
        } catch (error) {
            // Handle errors
            console.error('Error fetching data:', error);
        } finally {
            // Make sure to always set waitResult to false, whether there's an error or not
            setWaitResult(false);
            setFly(false)
            setTimeout(() => {
                setFly(true)
                setFlySetFlyFirst(false)
            }, 2000);
        }
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            handleAviatorResult();
        }, 5000);

        return () => {
            clearTimeout(timer); // Clear the timeout on component unmount or before the next effect
        };
    }, []);
    useEffect(() => {
        if (numberX === crassValue) {
            const timer = setTimeout(() => {
                setNumberX(0)
                setFly(true)
                setBet(false);
                setCashout(false);

                setFlySetFlyFirst(true)
                handleAviatorResult();
            }, 5000);

            return () => {
                clearTimeout(timer); // Clear the timeout on component unmount or before the next effect
            };
        }
    }, [numberX])


    const handleBet = async () => {
        try {
            if (loading) return; // Prevent multiple clicks
            setLoading(true);
            const data = await axios.post("https://hopeforcurrency.com/api/aviator_bet", { amount: amount, auth: authToken.auth, period_id: period_id })
            setBet(data?.data?.success)
            authHandle()
            setLoading(false)
        } catch (error) {

        }
    }

    const handleIncreasePagination = () => {
        setStarting(Number(starting) + 10)
        setEnding(Number(ending) + 10)

    }
    const handleDecreasePagination = () => {
        setStarting(Number(starting) - 10)
        setEnding(Number(ending) - 10)
    }
    const handleGetUserResult = async () => {
        try {
            const data = await axios.get(`https://hopeforcurrency.com/api/aviator_getuserresult?auth=${authToken.auth}&starting=${starting}&ending=${ending}`, starting, authToken.auth, ending)
            setUserResult(data?.data?.results)
            setTotalPageRecord(data?.data?.total_rows)
            // console.log("object", data?.data?.results)
        } catch (error) {

        }
    }

    const handleCashout = async () => {
        try {
            if (loading) return; // Prevent multiple clicks
            setLoading(true);
            const data = await axios.post("https://hopeforcurrency.com/api/aviator_cashout", { aviator_x: numberX, auth: authToken.auth, period_id: period_id })
            setCashout(data?.data?.success)

            if (data?.data?.success) {
                handleGetUserResult()
            }
            authHandle()
            setLoading(false)

        } catch (error) {

        }
    }


    const handleCurrentPeriod = async () => {
        try {
            if (loading) return; // Prevent multiple clicks
            setLoading(true);
            const data = await axios.post("https://hopeforcurrency.com/api/aviator_Period")
            setPeriod_id(data?.data?.period_id)
            setLoading(false)
        } catch (error) {

        }
    }

    useEffect(() => {


        const setupInterval = async () => {
            try {

                await handleGetHistoryResult();
                await handleCurrentPeriod()

            } catch (error) {

            }
        };


        // Call the setup function once when the component mounts
        const intervalPromise = setupInterval();
        return () => {

            intervalPromise.then(intervalss => clearInterval(intervalss));
        }
    }, [])



    useEffect(() => {




        handleGetUserResult()
        authHandle()
        const intervalId = setInterval(() => {
            // Update numberX
            setNumberX(prevNumber => {
                const newNumber = Number(prevNumber) + 0.02; // Ensure prevNumber is treated as a number
                const roundedNumber = parseFloat(newNumber.toFixed(2)); // Round the result to 2 decimal places
                // Check if numberX is greater than or equal to crassValue
                if (roundedNumber >= crassValue) {
                    clearInterval(intervalId); // Stop the interval
                    return crassValue; // Set numberX to crassValue
                }
                return roundedNumber;
            });

        }, 100); // Interval set to 1x in 1.5 seconds

        // Clean up function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [crassValue]);



    return (
        <>
            {/* header */}
            <header className='login-header Aviator-top-header  flex justify-between'>
                <div className="flex items-center">
                    <span className='icons icons-hover' onClick={() => navigate("/")}><FaArrowLeft className='white-color' /></span>
                    <h2 className="heading-h2 text-white text-xl font-bold ms-5">Aviator</h2>
                </div>
                <div className="dashboard-bell-section">
                    <span>₹ {Number(balance).toFixed(2)}</span>
                    <span className='icons icons-hover' onClick={() => navigate("/recharge")}><IoWallet /></span>
                    <span className='icons icons-hover'>< FaQuestionCircle /></span>
                </div>
            </header>

            {/* history section
 */}
            <div className="aviator-history-section">
                <div className="head flex justify-between items-center" onClick={handleOpenHead}>
                    <h4 className="heading-h4 mr-2"><Link className='mr-3'>History</Link>

                        <span className={history[0]?.result <= 5 ? 'bg-blue span-radius ' : history[0]?.result <= 10 ? "bg-violet span-radius" : "bg-red span-radius"}  >{history[0]?.result}x </span>

                        <span className={history[1]?.result <= 5 ? 'bg-blue span-radius ' : history[1]?.result <= 10 ? "bg-violet span-radius" : "bg-red span-radius"}  >{history[1]?.result}x </span>

                        <span className={history[2]?.result <= 5 ? 'bg-blue span-radius ' : history[2]?.result <= 10 ? "bg-violet span-radius" : "bg-red span-radius"}  >{history[2]?.result}x </span>

                        <span className={history[3]?.result <= 5 ? 'bg-blue span-radius ' : history[3]?.result <= 10 ? "bg-violet span-radius" : "bg-red span-radius"}  >{history[3]?.result}x </span>


                    </h4>
                    <div className="icons cursor-pointer" >< MdKeyboardArrowDown /></div>
                </div>
                <div className={openHead ? "content active" : "content"} >

                    {history?.map((item, i) => (
                        <span className={item.result <= 5 ? 'bg-blue span-radius ' : item.result <= 10 ? "bg-violet span-radius" : "bg-red span-radius"} key={i}>{item.result}x

                        </span>
                    ))}


                </div>
            </div>
            {/* cashed section */}
            <div className="aviator-history-section">
                <div className="head flex justify-between items-center" onClick={handleOpenCashed}>
                    <h4 className="heading-h4 mr-2"><Link >Cashed/Bet</Link>
                        <span className='color-green ml-1  '>9</span>/146
                    </h4>
                    <div className="icons cursor-pointer" >< MdKeyboardArrowDown /></div>
                </div>
                <div className={openCashed ? "content active" : "content"} >
                    <span className='bg-gray span-radius'>9.94x</span>
                    <span className='bg-gray span-radius'>9.94x</span>
                    <span className='bg-gray span-radius'>9.94x</span>
                    <span className='bg-gray span-radius'>9.94x</span>
                    <span className='bg-gray span-radius'>9.94x</span>
                    <span className='bg-gray span-radius'>9.94x</span>
                    <span className='bg-gray span-radius'>9.94x</span>
                    <span className='bg-gray span-radius'>9.94x</span>
                    <span className='bg-gray span-radius'>9.94x</span>
                    <span className='bg-gray span-radius'>9.94x</span>
                    <span className='bg-gray span-radius'>9.94x</span>


                </div>
            </div>
            <div className="container-section">
                <div className="shadow-section">
                    <div className="aviator-game-section">
                        {/* <div className={numberX === crassValue ? "aviator-game-container" : waitResult ? "aviator-game-container " : "aviator-game-container active"}>
                        </div>
                        <div className={fly ? flyfirst ? "aviator-fly-img " : "aviator-fly-img second" : "aviator-fly-img active"} >
                    
                            <div className={crassValue != null && numberX === crassValue ? "aviator goaway" : `aviator `} >
                                <img src={Plane} alt="" className={flyfirst ? "airplane " : "airplane active"} />
                               
                            </div>
                        </div> */}
                        <div id="back">
                            <div id="bg"></div>
                            <div className={numberX > 0 ? "planet active" : "planet"}></div>
                            <div className={numberX > 0 ? "star1 active" : "star1"}></div>
                            <div className={numberX > 0 ? "star2 active" : "star2"}></div>
                            <div className={numberX > 0 ? "star3 active" : "star3"}></div>
                            <div className={numberX > 0 ? "star4 active" : "star4"}></div>
                            <div className={numberX === 0 ? "rocket" : numberX === crassValue ? "rocket crass" : "rocket active"} ></div>
                        </div>

                        <div className="aviator-game-content ">
                            < div className={waitResult ? "first-content " : "first-content active"}>
                                <div className="rotate-img">
                                    <img src={RotateImg} alt="" />
                                </div>
                                <h3 className="heading-h3">WAITING FOR NEXT FLY</h3>
                                <div className=''>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 110" version="1.1" className="progress-bar">
                                        <path d="M 55 5    A 50 50 0 1 1 55 105 A 50 50 0 1 1 55 5 Z" style={{ fill: 'none', stroke: 'white', strokeWidth: 10 }} id="progress-path" />
                                    </svg>

                                </div>
                            </div>
                            <div className={waitResult ? "second-content " : "second-content active"}>
                                <h2 > {numberX === crassValue ? "FLEW  AWAY" : ""}</h2>
                                <h2 className={numberX === crassValue ? "text-red-500" : ""}>{numberX}x</h2>
                            </div>

                        </div>

                    </div>

                    {/*  */}
                    <div className="aviator-price-inpute">
                        <div className='input'>
                            <TextField
                                fullWidth
                                name="amount"
                                label="Amount"
                                id="amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                autoComplete="amount"
                            />
                        </div>

                        <div className='aviator-increase'>
                            <button onClick={decrease}><AiOutlineMinus /></button>
                            <button onClick={increease}><AiOutlinePlus /></button>
                        </div>
                    </div>

                    <div className="text-center p-3 pt-1">
                        <button
                            // className={bet ? (cashout ? "blue-btn bg-gray" : "blue-btn bg-green") : "blue-btn"}
                            className={bet ? (cashout ? "blue-btn bg-gray" : "blue-btn bg-green") : numberX > 0 ? "blue-btn bg-gray" : "blue-btn"}

                            disabled={(numberX > 0 && !bet) || (bet && cashout)}
                            onClick={() => { bet ? handleCashout() : handleBet() }}
                            style={{ width: "100%", height: "47px" }}
                        >
                            <span>{bet ? cashout ? "CONFIRM" : "cashout" : "CONFIRM"}</span>
                        </button>
                    </div>
                    <div className="text-center p-3 pt-1">
                        {/* <button className='blue-btn' onClick={() => { }} style={{ width: "100%", height: "47px" }}><span>cashout</span></button> */}
                    </div>
                </div>
            </div >
            {/* modal */}
            <Modal
                keepMounted
                open={opennew}
                onClose={handleCloseNew}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={styles}>
                    {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro, quibusdam.</p> */}
                    <div className="wingo-modal-box">
                        <div className="name-section gray">
                            <h3 className="heading-h3">
                                {/* Completed */}
                                {messages === "Your balance is low" ? "Failed" : "Completed "}
                            </h3>
                        </div>
                        <div className="modal-content">
                            <h5 className="heading-h5">{messages}</h5>
                            <div className="cancel-confirm">
                                <button className="ok-btn" onClick={() => { handleCloseNew(); }}>OK</button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>







            {/* aviator results */}

            <div className="aviator-record-section">
                <div className="aviator-record-head">
                    <div className="icons"><FaRegListAlt /></div>
                    <p>My Aviator Record</p>
                </div>
                <div className="container-section mt-3">
                    <div className="shadow-section">
                        <div className="prediction-section-color aviator-record-content">
                            <ul>
                                <div className='top-period'>
                                    <span>Period</span>
                                    <span>Bet</span>
                                    <span>Multi</span>
                                    <span>Cash out</span>
                                    <span>out</span>
                                </div >
                                {userResult.map((item, i) => (

                                    <li key={i}>
                                        <span>{item.date}</span>
                                        <span>{item.amount}</span>
                                        <span className={item.bet_close > 0 ? 'bg-violet span-radius' : 'bg-gray span-radius'}>
                                            {item.cashout === 0 ? item.bet_end : item.bet_close}x</span>
                                        <span className="">
                                            {item.cashout > 0 ? item.cashout : "-"}</span>
                                        <span className='color-green flex justify-center'>< BsFillPatchCheckFill /></span>

                                    </li>
                                ))}

                            </ul>
                            <div className='flex items-end justify-end py-3'>
                                <div className="wingo-pagination flex items-center">
                                    <div className="total-page">{starting}-{ending} of {totalPageRecord}</div>
                                    <div className="arrow">
                                        <button className={starting < 10 ? 'left disable-pagination' : 'left'} onClick={() => { handleDecreasePagination(); handleGetUserResult(); }} disabled={starting < 10}><MdKeyboardArrowLeft /></button>
                                        <button className={ending >= totalPageRecord ? 'right disable-pagination' : 'right'} onClick={() => { handleIncreasePagination(); handleGetUserResult(); }} disabled={ending >= totalPageRecord}><MdKeyboardArrowRight /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Aviator
// Given data



