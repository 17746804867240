import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/authToken';
import { ThemeProvider } from './context/ThemeProvider';
import { MoneyProvider } from './context/money';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AuthProvider>

      <MoneyProvider>

        <ThemeProvider>
          <App />
        </ThemeProvider>
      </MoneyProvider>


    </AuthProvider>
  </BrowserRouter>
);

