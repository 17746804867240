import React, { useEffect, useState } from 'react'
import "./wingo.css"
import { IoWallet, IoWalletOutline, IoWalletSharp } from 'react-icons/io5'
import { FaQuestionCircle } from "react-icons/fa";
import { FaArrowLeft } from 'react-icons/fa6';
import Box from '@mui/material/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { IoTrophy } from "react-icons/io5";
import GreenModal from './GreenModal';
import axios from 'axios';
import { FaCircle } from "react-icons/fa";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import asyncHandler from 'async-handler';
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useAuthToken } from '../../context/authToken';
import { useLocation, useNavigate } from 'react-router-dom';

import LinearProgress from '@mui/material/LinearProgress';
import Spinner from '../route/Spinner';

const selectNumberData = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
const Wingo = () => {
    const navigate = useNavigate()
    const [value, setValue] = React.useState('1');
    const [starting, setStarting] = useState(1)
    const [ending, setEnding] = useState(10)
    const [totalPage, setTotalPage] = useState(Number)
    const [myData, setMyData] = useState([])
    const [currentPeriodId, setCurrentPeriodId] = useState(Number)
    const [second, setSecond] = useState('')
    const [minute, setMinute] = useState('')
    const [openAllRecord, setOpenAllRecord] = useState(null)
    const [authToken, setAuthToken] = useAuthToken()
    const [loadTime, setLoadTime] = useState(false)
    const [currentPeriodTime, setCurrentPeriodTime] = useState([]);
    const firstChar = second.charAt(0); // Get the first character of the string
    const secondChar = second.charAt(1);
    const [colors, setColors] = useState('')
    const [game, setGame] = useState("parity")
    const [balance, setBalance] = useState('')
    const [totalPageRecord, setTotalPageRecord] = useState(Number)

    const firstCharM = minute.charAt(0)
    const secondCharM = minute.charAt(1)
    const [myPeriodRecord, setMyPeriodRecod] = useState([])
    const location = useLocation()
    const [update, setUpdate] = useState(false)
    const [loading, setLoading] = useState(false);
    // console.log("mii", minute, "ssee", second)
    const handleOpenAllRecord = (i) => {
        if (openAllRecord === i) {
            return setOpenAllRecord(null)
        }
        setOpenAllRecord(i)
    }

    // current id
    const handleCurrentPeriod = async () => {
        try {
            // if (loading) return; // Prevent multiple clicks
            // setLoading(true);
            const data = await axios.get(`https://hopeforcurrency.com/api/wingo_Period?game=${game}`)
            setCurrentPeriodId(data?.data?.period_id)
            setCurrentPeriodTime(data?.data?.next_cron_time)
            // console.log("object", data?.data?.next_cron_time)
            setLoading(false)
            var nextCronTime = data?.data?.next_cron_time;
            updateCountdown(nextCronTime);
            // setLoading(false)
        } catch (error) {

        }
    }
    const handleCurrentPeriodNew = async (currentPeriodId) => {
        try {
            const data = await axios.get(`https://hopeforcurrency.com/api/wingo_Period_lasttime?period=${currentPeriodId}`)
            setCurrentPeriodId(data?.data?.period_id)
            setCurrentPeriodTime(data?.data?.next_cron_time)
            // console.log("object", data?.data?.next_cron_time)
            console.log("currentPeriodId", currentPeriodId)

            var nextCronTime = data?.data?.next_cron_time;
            updateCountdown(nextCronTime);
        } catch (error) {
        }
    }
    const authHandle = async () => {
        try {
            const data = await axios.post("https://hopeforcurrency.com/api/getUserData", authToken)
            setBalance(data.data.user.balance)
        } catch (error) {
        }
    }
    // all color prediction
    const handlePrediction = async () => {
        try {
            if (loading) return; // Prevent multiple clicks
            setLoading(true);
            const data = await axios.get(`https://hopeforcurrency.com/api/getresult?starting=${starting}&ending=${ending}&game=${game}`,)

            setMyData(data?.data?.results)
            setTotalPage(data?.data?.total_rows)
            authHandle()
            setLoading(false);
        } catch (error) {

        }
    }

    function updateCountdown(nextCronTime) {
        // Given time
        var givenTime = new Date(nextCronTime);

        // Update the countdown every second
        // if (loadTime === false) {


        var countdownInterval = setInterval(function () {
            // Current time in Kolkata time zone
            var currentDate = new Date();
            var options = {
                timeZone: 'Asia/Kolkata',
                hour12: false,
            };
            var kolkataTime = currentDate.toLocaleString('en-US', options);

            // Calculate the difference in milliseconds
            var difference = givenTime - new Date(kolkataTime);

            // Convert the difference to minutes and seconds
            var minutes = Math.floor(difference / (1000 * 60));
            var seconds = Math.floor((difference / 1000) % 60);

            if (minutes >= 0) {
                // Display the time difference as a countdown
                setMinute(minutes.toString());
                setSecond(seconds.toString());
                // If both minutes and seconds are 0, resend the request to fetch next cron time
                if (minutes === 0 && seconds === 0) {
                    setTimeout(() => {
                        clearInterval(countdownInterval); // Stop the current countdown interval
                        handleCurrentPeriod();
                        // handleCurrentPeriodNew(currentPeriodId); // Resend the request to fetch next cron time
                        handlePrediction()
                    }, 1000)
                }
            } else {
                // Clear the interval if the countdown reaches zero
                clearInterval(countdownInterval);
                // handleCurrentPeriodNew(currentPeriodId)
            }
        }, 1000);
        //     setLoadTime(true)
        // }

    }

    // my prediction record
    const handlePredictionRecord = async () => {
        try {
            const data = await axios.post("https://hopeforcurrency.com/api/getuserresult", { auth: authToken.auth, starting: starting, ending: ending, game: game })
            setMyPeriodRecod(data?.data?.results)
            setTotalPageRecord(data?.data?.total_rows)

        } catch (error) {

        }
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [subvalue, setSubValue] = useState('11');
    const handleSubChange = (event, newValue) => {
        setSubValue(newValue);
    };

    useEffect(() => {

        // Function to handle initial setup
        const setupInterval = async () => {
            try {
                await handlePrediction();
                await handlePredictionRecord();
                localStorage.setItem('colors', colors);
                localStorage.setItem('game', game);
                handleCurrentPeriod();

            } catch (error) {

            }
        };

        // Call the setup function once when the component mounts
        const intervalPromise = setupInterval();

        // Clear the interval when the component unmounts
        return () => {
            intervalPromise.then(interval => clearInterval(interval));
        };
    }, [colors, currentPeriodId, game]); // Empty dependency array to ensure the effect runs only once
    // console.log("sssss", second)

    const handleIncreasePagination = () => {
        setStarting(Number(starting) + 10)
        setEnding(Number(ending) + 10)

    }
    const handleDecreasePagination = () => {
        setStarting(Number(starting) - 10)
        setEnding(Number(ending) - 10)
    }



    useEffect(() => {
        updateCountdown()
        if (minute === "0" && second === "0") {
            const timer = setTimeout(() => {
                console.log("run")

                handleCurrentPeriod()
                setUpdate(true); // Set the flag to true after calling handleHistory
            }, 500);
            // Clean up the timer to prevent multiple executions
            return () => clearTimeout(timer);
        }

    }, [minute, second, update]);

    useEffect(() => {
        authHandle()
    }, [authToken])


    return (
        <>
            {/* header */}
            <header className='login-header wingo-top-header  flex justify-between'>
                <div className="flex items-center">
                    <span className='icons icons-hover' onClick={() => navigate("/", {
                        state: location.pathname,
                    })}><FaArrowLeft className='white-color' /></span>
                    <h2 className="heading-h2 text-white text-xl font-bold ms-5">Win Go</h2>
                </div>
                <div className="dashboard-bell-section">
                    <span>₹ {Number(balance).toFixed(2)}</span>
                    <span className='icons icons-hover' onClick={() => navigate("/recharge", {
                        state: location.pathname,
                    })}><IoWalletSharp /></span>
                    <span className='icons icons-hover'>< FaQuestionCircle /></span>

                </div>
            </header>

            <div className="prediction-section">
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Parity" value="1" onClick={() => { setGame("Parity"); handlePredictionRecord(); }} />
                                <Tab label="Sapre" value="2" onClick={() => { setGame("Sapre"); handlePredictionRecord(); }} />
                                <Tab label="Bcone" value="3" onClick={() => { setGame("Bcone"); handlePredictionRecord(); }} />
                                <Tab label="Emerd" value="4" onClick={() => { setGame("Emerd"); handlePredictionRecord(); }} />
                            </TabList>
                        </Box>
                        {/* period section */}
                        {/* {loading ? (<Spinner />) : ( */}
                        <div className="container-section">
                            <div className="period-section">
                                <div className="period-box">
                                    <div className='flex'>
                                        <div className="icons"><IoTrophy /></div>
                                        <span className='ml-3'>Period</span>
                                    </div>
                                    <h3 className="heading-h3 mt-3">{currentPeriodId}</h3>
                                </div>
                                <div className="count-box">
                                    <p >Count Down</p>

                                    <div className="time-box mt-3">
                                        {/* {minutes < 10 ? <span>{`0${minutes}`}</span> : <span>{minutes}</span>} */}
                                        <span>{minute < 10 ? 0 : firstCharM}</span>
                                        <span>{secondCharM === "" ? (firstCharM === "" ? "0" : firstCharM) : secondCharM}</span>
                                        <span className='not-bg'>:</span>
                                        <span>{second < 10 ? 0 : firstChar}</span>
                                        <span>{secondChar === "" ? (firstChar === "" ? "0" : firstChar) : secondChar}</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* )} */}
                        {/* <button disabled={minute <= 0 && second < 30}> */}
                        <div className="container-section">


                            <div className={minute <= 0 && second < 30 ? "number-controll-section disabled-number" : "number-controll-section"}>
                                <div className="select-color-section">
                                    <span className='green' onClick={() => setColors("Green")}> <button disabled={minute <= 0 && second < 30}><GreenModal selectName="Join Green" GVRClassName={"name-section green"} period_id={currentPeriodId} /></button></span>
                                    <span className='violet' onClick={() => setColors("violet")}> <button disabled={minute <= 0 && second < 30}> <GreenModal selectName="Join Violet" GVRClassName={"name-section violet"} period_id={currentPeriodId} /> </button></span>
                                    <span className='red' onClick={() => setColors("Red")}> <button disabled={minute <= 0 && second < 30}><GreenModal selectName="Join Red" GVRClassName={"name-section red"} period_id={currentPeriodId} /></button></span>
                                </div>
                                <div className="select-number">
                                    {selectNumberData?.map((item, i) => (
                                        <span key={i} onClick={() => setColors(item)}> <button disabled={minute <= 0 && second < 30}><GreenModal selectName={item} GVRClassName={"name-section blue"} period_id={currentPeriodId} /></button></span>
                                    ))}

                                </div>
                            </div>
                        </div>
                        {/* </button> */}
                        <TabPanel value="1" onClick={handlePrediction}>
                            <div className="windo-subtab">
                                <TabContext value={subvalue}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleSubChange} aria-label="lab API tabs example">
                                            <Tab label="Parity Record" value="11" />
                                            <Tab label="My Parity Record" value="12" onClick={handlePredictionRecord} />

                                        </TabList>
                                    </Box>
                                    <TabPanel value="11">
                                        <div className="container-section">
                                            <div className="prediction-section-color">
                                                <div className="prediction-name">
                                                    <span>Period </span>
                                                    <span>Price </span>
                                                    <span>Number</span>
                                                    <span>Result</span>
                                                </div>
                                                {myData?.map((item, i) => (


                                                    <div className="prediction-result" key={i}>
                                                        <span>{item.period_id} </span>
                                                        <span>{item.open_price} </span>
                                                        <span>{item.result}</span>
                                                        <span className='color-control flex'>

                                                            <FaCircle className={item.color1 === "red" ? "red" : "green"} />
                                                            {item.color2 === "violate" ? <FaCircle className='color-violate' /> : ""}
                                                        </span>

                                                    </div>
                                                ))}

                                                <div className="wingo-pagination">
                                                    <div className="total-page">{starting}-{ending} of {totalPage}</div>
                                                    <div className="arrow">
                                                        <button className={starting < 10 ? 'left disable-pagination' : 'left'} onClick={() => { handleDecreasePagination(); handlePrediction(); }} disabled={starting < 10}><MdKeyboardArrowLeft /></button>
                                                        <button className={ending >= totalPage ? 'right disable-pagination' : 'right'} onClick={() => { handleIncreasePagination(); handlePrediction(); }} disabled={ending >= totalPage}><MdKeyboardArrowRight /></button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>



                                    </TabPanel>
                                    <TabPanel value="12">
                                        <div className="container-section">
                                            <div className="prediction-section-color">
                                                {myPeriodRecord.map((item, i) => (

                                                    <div className="my-record-section" key={i}>
                                                        <div className="id-section" onClick={() => handleOpenAllRecord(i)}>
                                                            <h3 className="heading-h3 flex">  {item.period}
                                                                {" "}
                                                                <span className={item.win_loss === "win" ? "color-green mx-2" : "color-red mx-2"} >   {item.win_loss}</span>

                                                                <span className={item.win_loss === "win" ? "color-green" : "color-red"} >

                                                                    <span className={item.win_loss === "wait" ? "d-none" : "d-block"}>
                                                                        {item.win_loss === "win" ? "+" : "-"} {" "}
                                                                        {(item.am - item.am * 0.02).toFixed(2)}
                                                                    </span>

                                                                </span>
                                                            </h3>
                                                            {openAllRecord === i ? <span><IoIosArrowUp /></span> : <span><IoIosArrowDown /></span>}
                                                        </div>
                                                        <div className={openAllRecord === i ? "all-prediction-record active" : "all-prediction-record"}>
                                                            <h3 className="heading-h3 ">Period Detail</h3>
                                                            <ul>
                                                                <li>
                                                                    <span>Period</span>
                                                                    <span> {item.period}</span>
                                                                </li>
                                                                <li>
                                                                    <span>Contract Money</span>
                                                                    <span>{item.amount} </span>
                                                                </li>
                                                                <li>
                                                                    <span>Contract Count</span>
                                                                    <span>{item.count}</span>
                                                                </li>
                                                                <li>
                                                                    <span>Fee</span>
                                                                    <span className='color-blue'>{(item.amount * 0.02).toFixed(2
                                                                    )}</span>
                                                                </li>
                                                                <li>
                                                                    <span>Open Price</span>
                                                                    <span >{item.price}</span>
                                                                </li>

                                                                <li>
                                                                    <span>Result</span>
                                                                    <div><span className='color-blue'>{item.result} </span><span className={item.color === "green" ? "color-green" : "color-red"}> {" "}{item.color}</span><span className='color-violet'>  {" "} {item.color2} </span> </div>
                                                                </li>

                                                                <li className=''>
                                                                    <span>Select</span>
                                                                    <span className={item.bet_color === "Green" ? "color-green" : (item.bet_color === "Red") ? "color-red" : (item.bet_color === "violet") ? "color-violet" : "color-blue"}>{item.bet_color}</span>
                                                                </li>
                                                                {/* select end */}
                                                                <li>
                                                                    <span>Status</span>
                                                                    {/* <span className='color-red' >{item.win_loss}</span> */}
                                                                    <span className={item.win_loss === "win" ? "color-green" : "color-red"} >{item.win_loss}</span>
                                                                </li>


                                                                <li>
                                                                    <span>Amount</span>
                                                                    <span className={item.win_loss === "wait" ? "d-none" : "d-block"}>
                                                                        <span className={item.win_loss === "win" ? "color-green" : "color-red"} >
                                                                            {item.win_loss === "win" ? "+" : "-"} {" "}
                                                                            {(item.am - item.am * 0.02).toFixed(2)}
                                                                        </span>
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span>Create Time</span>
                                                                    <span >{item.time}</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                ))}

                                                <div className="wingo-pagination">
                                                    <div className="total-page">{starting}-{ending} of {totalPageRecord}</div>
                                                    <div className="arrow">
                                                        <button className={starting < 10 ? 'left disable-pagination' : 'left'} onClick={() => { handleDecreasePagination(); handlePredictionRecord(); }} disabled={starting < 10}><MdKeyboardArrowLeft /></button>
                                                        <button className={ending >= totalPageRecord ? 'right disable-pagination' : 'right'} onClick={() => { handleIncreasePagination(); handlePredictionRecord(); }} disabled={ending >= totalPageRecord}><MdKeyboardArrowRight /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </TabPanel>
                                </TabContext>
                            </div>

                        </TabPanel>
                        <TabPanel value="2" >
                            <div className="windo-subtab">
                                <TabContext value={subvalue}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleSubChange} aria-label="lab API tabs example">
                                            <Tab label="Sapre Record" value="11" />
                                            <Tab label="My Sapre Record" value="12" />

                                        </TabList>
                                    </Box>
                                    <TabPanel value="11">
                                        <div className="container-section">
                                            <div className="prediction-section-color">
                                                <div className="prediction-name">
                                                    <span>Period </span>
                                                    <span>Price </span>
                                                    <span>Number</span>
                                                    <span>Result</span>
                                                </div>
                                                {myData?.map((item, i) => (


                                                    <div className="prediction-result" key={i}>
                                                        <span>{item.period_id} </span>
                                                        <span>{item.open_price} </span>
                                                        <span>{item.result}</span>
                                                        <span className='color-control flex'>

                                                            <FaCircle className={item.color1 === "red" ? "red" : "green"} />
                                                            {item.color2 === "violate" ? <FaCircle className='color-violate' /> : ""}
                                                        </span>

                                                    </div>
                                                ))}

                                                <div className="wingo-pagination">
                                                    <div className="total-page">{starting}-{ending} of {totalPage}</div>
                                                    <div className="arrow">
                                                        <button className={starting < 10 ? 'left disable-pagination' : 'left'} onClick={() => { handleDecreasePagination(); handlePrediction(); }} disabled={starting < 10}><MdKeyboardArrowLeft /></button>
                                                        <button className={ending >= totalPage ? 'right disable-pagination' : 'right'} onClick={() => { handleIncreasePagination(); handlePrediction(); }} disabled={ending >= totalPage}><MdKeyboardArrowRight /></button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>



                                    </TabPanel>
                                    <TabPanel value="12">
                                        <div className="container-section">
                                            <div className="prediction-section-color">
                                                {myPeriodRecord.map((item, i) => (

                                                    <div className="my-record-section" key={i}>
                                                        <div className="id-section" onClick={() => handleOpenAllRecord(i)}>
                                                            <h3 className="heading-h3 flex">  {item.period}
                                                                {" "}
                                                                <span className={item.win_loss === "win" ? "color-green mx-2" : "color-red mx-2"} >   {item.win_loss}</span>

                                                                <span className={item.win_loss === "win" ? "color-green" : "color-red"} >

                                                                    <span className={item.win_loss === "wait" ? "d-none" : "d-block"}>
                                                                        {item.win_loss === "win" ? "+" : "-"} {" "}
                                                                        {(item.am - item.am * 0.02).toFixed(2)}
                                                                    </span>

                                                                </span>
                                                            </h3>
                                                            {openAllRecord === i ? <span><IoIosArrowUp /></span> : <span><IoIosArrowDown /></span>}
                                                        </div>
                                                        <div className={openAllRecord === i ? "all-prediction-record active" : "all-prediction-record"}>
                                                            <h3 className="heading-h3 ">Period Detail</h3>
                                                            <ul>
                                                                <li>
                                                                    <span>Period</span>
                                                                    <span> {item.period}</span>
                                                                </li>
                                                                <li>
                                                                    <span>Contract Money</span>
                                                                    <span>{item.amount} </span>
                                                                </li>
                                                                <li>
                                                                    <span>Contract Count</span>
                                                                    <span>{item.count}</span>
                                                                </li>
                                                                <li>
                                                                    <span>Fee</span>
                                                                    <span className='color-blue'>{(item.amount * 0.02.toFixed(2))}</span>
                                                                </li>
                                                                <li>
                                                                    <span>Open Price</span>
                                                                    <span >{item.price}</span>
                                                                </li>

                                                                <li>
                                                                    <span>Result</span>
                                                                    <div><span className='color-blue'>{item.result} </span><span className={item.color === "green" ? "color-green" : "color-red"}> {" "}{item.color}</span><span className='color-violet'>  {" "} {item.color2} </span> </div>
                                                                </li>

                                                                <li className=''>
                                                                    <span>Select</span>
                                                                    <span className={item.bet_color === "Green" ? "color-green" : (item.bet_color === "Red") ? "color-red" : (item.bet_color === "violet") ? "color-violet" : "color-blue"}>{item.bet_color}</span>
                                                                </li>
                                                                {/* select end */}
                                                                <li>
                                                                    <span>Status</span>
                                                                    {/* <span className='color-red' >{item.win_loss}</span> */}
                                                                    <span className={item.win_loss === "win" ? "color-green" : "color-red"} >{item.win_loss}</span>
                                                                </li>


                                                                <li>
                                                                    <span>Amount</span>
                                                                    <span className={item.win_loss === "wait" ? "d-none" : "d-block"}>
                                                                        <span className={item.win_loss === "win" ? "color-green" : "color-red"} >
                                                                            {item.win_loss === "win" ? "+" : "-"} {" "}
                                                                            {(item.am - item.am * 0.02).toFixed(2)}
                                                                        </span>
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span>Create Time</span>
                                                                    <span >{item.time}</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                ))}

                                                <div className="wingo-pagination">
                                                    <div className="total-page">{starting}-{ending} of {totalPageRecord}</div>
                                                    <div className="arrow">
                                                        <button className={starting < 10 ? 'left disable-pagination' : 'left'} onClick={() => { handleDecreasePagination(); handlePredictionRecord(); }} disabled={starting < 10}><MdKeyboardArrowLeft /></button>
                                                        <button className={ending >= totalPageRecord ? 'right disable-pagination' : 'right'} onClick={() => { handleIncreasePagination(); handlePredictionRecord(); }} disabled={ending >= totalPageRecord}><MdKeyboardArrowRight /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </TabPanel>
                                </TabContext>
                            </div>
                        </TabPanel>
                        <TabPanel value="3" onClick={handlePrediction}>
                            <div className="windo-subtab">
                                <TabContext value={subvalue}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleSubChange} aria-label="lab API tabs example">
                                            <Tab label="Becone Record" value="11" />
                                            <Tab label="My Becone Record" value="12" />

                                        </TabList>
                                    </Box>
                                    <TabPanel value="11">
                                        <div className="container-section">
                                            <div className="prediction-section-color">
                                                <div className="prediction-name">
                                                    <span>Period </span>
                                                    <span>Price </span>
                                                    <span>Number</span>
                                                    <span>Result</span>
                                                </div>
                                                {myData?.map((item, i) => (


                                                    <div className="prediction-result" key={i}>
                                                        <span>{item.period_id} </span>
                                                        <span>{item.open_price} </span>
                                                        <span>{item.result}</span>
                                                        <span className='color-control flex'>

                                                            <FaCircle className={item.color1 === "red" ? "red" : "green"} />
                                                            {item.color2 === "violate" ? <FaCircle className='color-violate' /> : ""}
                                                        </span>

                                                    </div>
                                                ))}

                                                <div className="wingo-pagination">
                                                    <div className="total-page">{starting}-{ending} of {totalPage}</div>
                                                    <div className="arrow">
                                                        <button className={starting < 10 ? 'left disable-pagination' : 'left'} onClick={() => { handleDecreasePagination(); handlePrediction(); }} disabled={starting < 10}><MdKeyboardArrowLeft /></button>
                                                        <button className={ending >= totalPage ? 'right disable-pagination' : 'right'} onClick={() => { handleIncreasePagination(); handlePrediction(); }} disabled={ending >= totalPage}><MdKeyboardArrowRight /></button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>



                                    </TabPanel>
                                    <TabPanel value="12">
                                        <div className="container-section">
                                            <div className="prediction-section-color">
                                                {myPeriodRecord.map((item, i) => (

                                                    <div className="my-record-section" key={i}>
                                                        <div className="id-section" onClick={() => handleOpenAllRecord(i)}>
                                                            <h3 className="heading-h3 flex">  {item.period}
                                                                {" "}
                                                                <span className={item.win_loss === "win" ? "color-green mx-2" : "color-red mx-2"} >   {item.win_loss}</span>

                                                                <span className={item.win_loss === "win" ? "color-green" : "color-red"} >

                                                                    <span className={item.win_loss === "wait" ? "d-none" : "d-block"}>
                                                                        {item.win_loss === "win" ? "+" : "-"} {" "}
                                                                        {(item.am - item.am * 0.02).toFixed(2)}
                                                                    </span>

                                                                </span>
                                                            </h3>
                                                            {openAllRecord === i ? <span><IoIosArrowUp /></span> : <span><IoIosArrowDown /></span>}
                                                        </div>
                                                        <div className={openAllRecord === i ? "all-prediction-record active" : "all-prediction-record"}>
                                                            <h3 className="heading-h3 ">Period Detail</h3>
                                                            <ul>
                                                                <li>
                                                                    <span>Period</span>
                                                                    <span> {item.period}</span>
                                                                </li>
                                                                <li>
                                                                    <span>Contract Money</span>
                                                                    <span>{item.amount} </span>
                                                                </li>
                                                                <li>
                                                                    <span>Contract Count</span>
                                                                    <span>{item.count}</span>
                                                                </li>
                                                                <li>
                                                                    <span>Fee</span>
                                                                    <span className='color-blue'>{(item.amount * 0.02).toFixed(2)}</span>
                                                                </li>
                                                                <li>
                                                                    <span>Open Price</span>
                                                                    <span >{item.price}</span>
                                                                </li>

                                                                <li>
                                                                    <span>Result</span>
                                                                    <div><span className='color-blue'>{item.result} </span><span className={item.color === "green" ? "color-green" : "color-red"}> {" "}{item.color}</span><span className='color-violet'>  {" "} {item.color2} </span> </div>
                                                                </li>

                                                                <li className=''>
                                                                    <span>Select</span>
                                                                    <span className={item.bet_color === "Green" ? "color-green" : (item.bet_color === "Red") ? "color-red" : (item.bet_color === "violet") ? "color-violet" : "color-blue"}>{item.bet_color}</span>
                                                                </li>
                                                                {/* select end */}
                                                                <li>
                                                                    <span>Status</span>
                                                                    {/* <span className='color-red' >{item.win_loss}</span> */}
                                                                    <span className={item.win_loss === "win" ? "color-green" : "color-red"} >{item.win_loss}</span>
                                                                </li>


                                                                <li>
                                                                    <span>Amount</span>
                                                                    <span className={item.win_loss === "wait" ? "d-none" : "d-block"}>
                                                                        <span className={item.win_loss === "win" ? "color-green" : "color-red"} >
                                                                            {item.win_loss === "win" ? "+" : "-"} {" "}
                                                                            {(item.am - item.am * 0.02).toFixed(2)}
                                                                        </span>
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span>Create Time</span>
                                                                    <span >{item.time}</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                ))}

                                                <div className="wingo-pagination">
                                                    <div className="total-page">{starting}-{ending} of {totalPageRecord}</div>
                                                    <div className="arrow">
                                                        <button className={starting < 10 ? 'left disable-pagination' : 'left'} onClick={() => { handleDecreasePagination(); handlePredictionRecord(); }} disabled={starting < 10}><MdKeyboardArrowLeft /></button>
                                                        <button className={ending >= totalPageRecord ? 'right disable-pagination' : 'right'} onClick={() => { handleIncreasePagination(); handlePredictionRecord(); }} disabled={ending >= totalPageRecord}><MdKeyboardArrowRight /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </TabPanel>
                                </TabContext>
                            </div>
                        </TabPanel>
                        <TabPanel value="4" onClick={handlePrediction}>

                            <div className="windo-subtab">
                                <TabContext value={subvalue}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleSubChange} aria-label="lab API tabs example">
                                            <Tab label="Emerd Record" value="11" />
                                            <Tab label="My Emerd Record" value="12" />

                                        </TabList>
                                    </Box>
                                    <TabPanel value="11">
                                        <div className="container-section">
                                            <div className="prediction-section-color">
                                                <div className="prediction-name">
                                                    <span>Period </span>
                                                    <span>Price </span>
                                                    <span>Number</span>
                                                    <span>Result</span>
                                                </div>
                                                {myData?.map((item, i) => (


                                                    <div className="prediction-result" key={i}>
                                                        <span>{item.period_id} </span>
                                                        <span>{item.open_price} </span>
                                                        <span>{item.result}</span>
                                                        <span className='color-control flex'>

                                                            <FaCircle className={item.color1 === "red" ? "red" : "green"} />
                                                            {item.color2 === "violate" ? <FaCircle className='color-violate' /> : ""}
                                                        </span>

                                                    </div>
                                                ))}

                                                <div className="wingo-pagination">
                                                    <div className="total-page">{starting}-{ending} of {totalPage}</div>
                                                    <div className="arrow">
                                                        <button className={starting < 10 ? 'left disable-pagination' : 'left'} onClick={() => { handleDecreasePagination(); handlePrediction(); }} disabled={starting < 10}><MdKeyboardArrowLeft /></button>
                                                        <button className={ending >= totalPage ? 'right disable-pagination' : 'right'} onClick={() => { handleIncreasePagination(); handlePrediction(); }} disabled={ending >= totalPage}><MdKeyboardArrowRight /></button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>



                                    </TabPanel>
                                    <TabPanel value="12">
                                        <div className="container-section">
                                            <div className="prediction-section-color">
                                                {myPeriodRecord.map((item, i) => (

                                                    <div className="my-record-section" key={i}>
                                                        <div className="id-section" onClick={() => handleOpenAllRecord(i)}>
                                                            <h3 className="heading-h3 flex">  {item.period}
                                                                {" "}
                                                                <span className={item.win_loss === "win" ? "color-green mx-2" : "color-red mx-2"} >   {item.win_loss}</span>

                                                                <span className={item.win_loss === "win" ? "color-green" : "color-red"} >

                                                                    <span className={item.win_loss === "wait" ? "d-none" : "d-block"}>
                                                                        {item.win_loss === "win" ? "+" : "-"} {" "}
                                                                        {(item.am - item.am * 0.02).toFixed(2)}
                                                                    </span>

                                                                </span>
                                                            </h3>
                                                            {openAllRecord === i ? <span><IoIosArrowUp /></span> : <span><IoIosArrowDown /></span>}
                                                        </div>
                                                        <div className={openAllRecord === i ? "all-prediction-record active" : "all-prediction-record"}>
                                                            <h3 className="heading-h3 ">Period Detail</h3>
                                                            <ul>
                                                                <li>
                                                                    <span>Period</span>
                                                                    <span> {item.period}</span>
                                                                </li>
                                                                <li>
                                                                    <span>Contract Money</span>
                                                                    <span>{item.amount} </span>
                                                                </li>
                                                                <li>
                                                                    <span>Contract Count</span>
                                                                    <span>{item.count}</span>
                                                                </li>
                                                                <li>
                                                                    <span>Fee</span>
                                                                    <span className='color-blue'>{(item.amount * 0.02).toFixed(2)}</span>
                                                                </li>
                                                                <li>
                                                                    <span>Open Price</span>
                                                                    <span >{item.price}</span>
                                                                </li>

                                                                <li>
                                                                    <span>Result</span>
                                                                    <div><span className='color-blue'>{item.result} </span><span className={item.color === "green" ? "color-green" : "color-red"}> {" "}{item.color}</span><span className='color-violet'>  {" "} {item.color2} </span> </div>
                                                                </li>

                                                                <li className=''>
                                                                    <span>Select</span>
                                                                    <span className={item.bet_color === "Green" ? "color-green" : (item.bet_color === "Red") ? "color-red" : (item.bet_color === "violet") ? "color-violet" : "color-blue"}>{item.bet_color}</span>
                                                                </li>
                                                                {/* select end */}
                                                                <li>
                                                                    <span>Status</span>
                                                                    {/* <span className='color-red' >{item.win_loss}</span> */}
                                                                    <span className={item.win_loss === "win" ? "color-green" : "color-red"} >{item.win_loss}</span>
                                                                </li>


                                                                <li>
                                                                    <span>Amount</span>
                                                                    <span className={item.win_loss === "wait" ? "d-none" : "d-block"}>
                                                                        <span className={item.win_loss === "win" ? "color-green" : "color-red"} >
                                                                            {item.win_loss === "win" ? "+" : "-"} {" "}
                                                                            {(item.am - item.am * 0.02).toFixed(2)}
                                                                        </span>
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span>Create Time</span>
                                                                    <span >{item.time}</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                ))}

                                                <div className="wingo-pagination">
                                                    <div className="total-page">{starting}-{ending} of {totalPageRecord}</div>
                                                    <div className="arrow">
                                                        <button className={starting < 10 ? 'left disable-pagination' : 'left'} onClick={() => { handleDecreasePagination(); handlePredictionRecord(); }} disabled={starting < 10}><MdKeyboardArrowLeft /></button>
                                                        <button className={ending >= totalPageRecord ? 'right disable-pagination' : 'right'} onClick={() => { handleIncreasePagination(); handlePredictionRecord(); }} disabled={ending >= totalPageRecord}><MdKeyboardArrowRight /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </TabPanel>
                                </TabContext>
                            </div>

                        </TabPanel>
                    </TabContext>
                </Box>
            </div>
        </>
    )
}

export default Wingo
// Given data



